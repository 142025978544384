import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "hairColor",
      d: "M501.5 593C354.872 593 236 727.667 236 893.8V1200.2C236 1366.32 354.872 1384 501.5 1384C648.128 1384 767 1366.32 767 1200.2V893.8C767 727.667 648.128 593 501.5 593Z",
      fill: "#FEDC58",
      stroke: "#1B1A1A",
      "stroke-width": "12",
      "stroke-miterlimit": "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M501.5 593C354.872 593 236 727.667 236 893.8V1200.2C236 1366.32 354.872 1380.5 501.5 1380.5C648.128 1380.5 767 1366.32 767 1200.2V893.8C767 727.667 648.128 593 501.5 593Z",
      fill: "black",
      "fill-opacity": "0.07"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-hair-back_10",
      cx: "501",
      cy: "672.37",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }