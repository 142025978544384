import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M302 251C302 291.317 269.317 324 229 324C188.683 324 156 291.317 156 251C156 210.683 188.683 178 229 178C269.317 178 302 210.683 302 251ZM229 316C264.899 316 294 286.899 294 251C294 215.101 264.899 186 229 186C193.101 186 164 215.101 164 251C164 286.899 193.101 316 229 316Z\" fill=\"#352712\"></path><circle cx=\"229\" cy=\"251\" r=\"65\" fill=\"white\"></circle><mask id=\"mask0_1594_4720\" style=\"mask-type:alpha;\" maskUnits=\"userSpaceOnUse\" x=\"164\" y=\"186\" width=\"130\" height=\"130\"><circle cx=\"229\" cy=\"251\" r=\"65\" fill=\"white\"></circle></mask><g mask=\"url(#mask0_1594_4720)\"><circle cx=\"229\" cy=\"217\" r=\"58\" fill=\"#352712\"></circle></g><circle cx=\"253\" cy=\"252\" r=\"7\" fill=\"white\"></circle><circle cx=\"201\" cy=\"213\" r=\"14\" fill=\"white\"></circle><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M544 251C544 291.317 511.317 324 471 324C430.683 324 398 291.317 398 251C398 210.683 430.683 178 471 178C511.317 178 544 210.683 544 251ZM471 316C506.899 316 536 286.899 536 251C536 215.101 506.899 186 471 186C435.101 186 406 215.101 406 251C406 286.899 435.101 316 471 316Z\" fill=\"#352712\"></path><circle cx=\"471\" cy=\"251\" r=\"65\" fill=\"white\"></circle><mask id=\"mask1_1594_4720\" style=\"mask-type:alpha;\" maskUnits=\"userSpaceOnUse\" x=\"406\" y=\"186\" width=\"130\" height=\"130\"><circle cx=\"471\" cy=\"251\" r=\"65\" fill=\"white\"></circle></mask><g mask=\"url(#mask1_1594_4720)\"><circle cx=\"471\" cy=\"217\" r=\"58\" fill=\"#352712\"></circle></g><circle cx=\"495\" cy=\"252\" r=\"7\" fill=\"white\"></circle><circle cx=\"443\" cy=\"213\" r=\"14\" fill=\"white\"></circle><circle cx=\"350\" cy=\"250\" r=\"10\" id=\"anchor-eyes_37\"></circle>", 13)
  ])))
}
export default { render: render }