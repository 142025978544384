import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M313.982 171C304.737 171 296.523 168.022 290.735 162.552C287.214 159.227 287.073 153.69 290.424 150.186C293.769 146.688 299.334 146.541 302.858 149.877C305.457 152.334 309.818 153.634 314.812 153.493C320.268 153.321 325.886 151.622 331.836 149.82L372.178 137.594C377.892 135.863 383.291 134.227 387.794 131.509C391.904 129.02 394.8 125.698 395.732 122.387C397.046 117.739 401.901 115.028 406.573 116.324C411.252 117.626 413.982 122.454 412.672 127.103C410.518 134.743 404.931 141.611 396.936 146.45C390.537 150.319 383.507 152.447 377.306 154.325L336.96 166.548C330.487 168.507 323.153 170.723 315.366 170.968C314.9 170.989 314.441 171 313.982 171Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_12",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }