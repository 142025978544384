import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M327.981 97.0638H217.223C212.118 97.0638 207.013 96.9203 201.909 97.0638H201.689C196.887 97.0638 192.287 105.314 192.508 115C192.548 119.732 193.528 124.249 195.241 127.596C196.954 130.943 199.266 132.858 201.689 132.936H312.447C317.543 132.936 322.656 133.08 327.761 132.936H327.981C332.783 132.936 337.383 124.686 337.162 115C337.12 110.269 336.139 105.755 334.426 102.409C332.714 99.0631 330.403 97.1467 327.981 97.0638Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "2"
    }, null, -1),
    _createElementVNode("path", {
      d: "M555.188 97.0638H334.481C324.309 97.0638 314.138 96.9203 303.966 97.0638H303.527C293.959 97.0638 284.793 105.314 285.232 115C285.312 119.732 287.265 124.249 290.679 127.596C294.093 130.943 298.7 132.858 303.527 132.936H524.234C534.406 132.936 544.577 133.08 554.749 132.936H555.188C564.756 132.936 573.922 124.686 573.483 115C573.398 110.269 571.444 105.755 568.031 102.409C564.618 99.0631 560.014 97.1467 555.188 97.0638Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "2"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_8",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }