import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M296.278 124.951C302.656 149.957 327.602 167.26 353.22 166.997C377.831 166.742 401.193 149.9 404.875 124.951C405.498 120.738 403.824 116.468 399.327 115.241C395.589 114.221 390.184 116.508 389.557 120.754C386.885 138.867 371.47 151.017 353.216 151.208C334.477 151.402 316.271 139.089 311.592 120.754C309.083 110.914 293.761 115.083 296.278 124.951Z",
      fill: "#282828"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_15",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }