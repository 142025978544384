import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M301 250C301 290.317 268.317 323 228 323C187.683 323 155 290.317 155 250C155 209.683 187.683 177 228 177C268.317 177 301 209.683 301 250ZM228 315C263.899 315 293 285.899 293 250C293 214.101 263.899 185 228 185C192.101 185 163 214.101 163 250C163 285.899 192.101 315 228 315Z\" fill=\"#352712\"></path><circle cx=\"228\" cy=\"250\" r=\"65\" fill=\"white\"></circle><circle cx=\"228\" cy=\"250\" r=\"35\" fill=\"#352712\"></circle><circle cx=\"238\" cy=\"262\" r=\"3\" fill=\"white\"></circle><circle cx=\"225\" cy=\"245\" r=\"10\" fill=\"white\"></circle><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M545 250C545 290.317 512.317 323 472 323C431.683 323 399 290.317 399 250C399 209.683 431.683 177 472 177C512.317 177 545 209.683 545 250ZM472 315C507.899 315 537 285.899 537 250C537 214.101 507.899 185 472 185C436.101 185 407 214.101 407 250C407 285.899 436.101 315 472 315Z\" fill=\"#352712\"></path><circle cx=\"472\" cy=\"250\" r=\"65\" fill=\"white\"></circle><circle cx=\"472\" cy=\"250\" r=\"35\" fill=\"#352712\"></circle><circle cx=\"482\" cy=\"262\" r=\"3\" fill=\"white\"></circle><circle cx=\"469\" cy=\"245\" r=\"10\" fill=\"white\"></circle><circle cx=\"350\" cy=\"250\" r=\"10\" id=\"anchor-eyes_31\"></circle>", 11)
  ])))
}
export default { render: render }