import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1101",
  height: "700",
  viewBox: "0 0 1101 700",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M598 347C566.975 333.799 534.025 333.799 503 347C503 340.967 503 334.934 503 328.901C534.025 315.7 566.975 315.7 598 328.901C598 334.929 598 340.962 598 347Z\" fill=\"#010202\"></path><path d=\"M315.135 336.369C315.135 336.369 310.824 275.447 397.922 276.004C459.617 276.399 517.79 278.59 526.414 321.278C535.037 363.965 505.717 412.689 440.178 416.569C374.64 420.45 311.687 398.46 315.135 336.369Z\" fill=\"#182121\"></path><path d=\"M521.673 365.983C411.187 363.983 323.916 339.526 323.916 309.618C323.916 307.928 324.191 306.272 324.778 304.616C334.919 289.922 355.65 275.744 397.906 276.02C459.617 276.399 517.81 278.573 526.432 321.276C529.434 336.181 527.847 351.806 521.673 365.983Z\" fill=\"#232B2B\"></path><path d=\"M785.163 336.369C785.163 336.369 789.475 275.447 702.377 276.004C640.682 276.399 582.509 278.59 573.884 321.278C565.262 363.965 594.582 412.689 660.12 416.569C725.659 420.45 788.611 398.46 785.163 336.369Z\" fill=\"#182121\"></path><path d=\"M578.625 365.983C689.111 363.983 776.382 339.526 776.382 309.618C776.382 307.928 776.106 306.272 775.52 304.616C765.379 289.922 744.648 275.744 702.391 276.02C640.68 276.399 582.488 278.573 573.865 321.276C570.864 336.181 572.451 351.806 578.625 365.983Z\" fill=\"#232B2B\"></path><circle id=\"anchor-eyewear_2\" cx=\"551\" cy=\"350\" r=\"10\"></circle>", 6)
  ])))
}
export default { render: render }