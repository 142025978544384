import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path class=\"hairColor\" d=\"M499.883 666C352.702 666 233.383 791.148 233.383 945.538V1097.4C241.781 1107.18 255.858 1115.59 274.133 1122.67L285.933 1085.71L298.743 1130.74C434.071 1168.5 712.193 1154.23 766.383 1097.4V945.538C766.383 791.148 647.063 666 499.883 666Z\" fill=\"#CB36FF\"></path><path d=\"M499.883 666C352.702 666 233.383 791.148 233.383 945.538V1097.4C241.781 1107.18 255.858 1115.59 274.133 1122.67L285.933 1085.71L298.743 1130.74C434.071 1168.5 712.193 1154.23 766.383 1097.4V945.538C766.383 791.148 647.063 666 499.883 666Z\" stroke=\"#1B1A1A\" stroke-width=\"12\" stroke-miterlimit=\"10\"></path><path d=\"M261.903 1123.97C263.06 1124.42 264.225 1124.84 265.408 1125.3L277.089 1090.57L289.759 1132.89C329.384 1143.36 381.373 1149.61 436.341 1151.77L460.245 1128.88L255.716 975.511C255.716 975.511 250.336 1064.1 261.903 1123.97Z\" fill=\"black\" fill-opacity=\"0.25\"></path><path d=\"M589.277 995.601L614.769 1145.03C649.819 1141.23 681.867 1136.04 708.548 1129.51C708.548 1129.51 741.875 1097.23 738.28 975.169C734.684 853.108 589.277 995.601 589.277 995.601Z\" fill=\"black\" fill-opacity=\"0.25\"></path><circle id=\"anchor-hair-back_2\" cx=\"501\" cy=\"672.37\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }