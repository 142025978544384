import { loadStripe } from '@stripe/stripe-js';
import { app } from '@/main';
import { Hotjar } from '@/core/services/Hotjar';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import {
    ANONYMOUSEVENTS,
    AnonymousTrackingService,
} from '@/core/services/TrackingService/AnonymousTrackingService';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import { fetchAndUpdateUser } from '@/flows/Authentication/helpers/AuthHelpers';

export default {
    namespaced: true,
    state: {
        api: null,
        elements: null,
        paymentElement: null,
        stripeLoaded: false,
        hasGooglePay: false,
        hasApplePay: false,
        paymentMethodType: '',
        error: '',
    },
    getters: {
        isStripeLoaded: (state) => {
            return state.stripeLoaded;
        },
        stripeElements: (state) => {
            return state.elements;
        },
        stripePaymentElement: (state) => {
            return state.paymentElement;
        },
        stripeError: (state) => {
            return state.error;
        },
    },
    mutations: {
        api: (state, next) => {
            state.api = next;
        },
        stripeLoaded: (state, next) => {
            state.stripeLoaded = next;
        },
        hasApplePay: (state, next) => {
            state.hasApplePay = next;
        },
        hasGooglePay: (state, next) => {
            state.hasGooglePay = next;
        },
        setError: (state, next) => {
            state.error = next;
        },
        setElements: (state, next) => {
            state.elements = next;
        },
        setPaymentElement: (state, next) => {
            state.paymentElement = next;
        },
        setPaymentMethodType: (state, next) => {
            state.paymentMethodType = next;
        },
    },
    actions: {
        init: async () => {},
        initStripe: async (store) => {
            if (!store.getters.isStripeLoaded) {
                await store.commit(
                    'api',
                    await loadStripe(import.meta.env.VITE_STRIPE_CLIENT_TOKEN),
                );

                await store.commit('stripeLoaded', true);
            }
        },
        checkWallets: async (store, tries = 1) => {
            if (store.getters.isStripeLoaded) {
                const paymentRequest = store.state.api.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: { amount: 0, label: 'Subscription' },
                });

                const canMakePayment = await paymentRequest.canMakePayment();

                store.commit('hasApplePay', canMakePayment?.applePay);

                store.commit('hasGooglePay', canMakePayment?.googlePay);
            } else if (tries < 5) {
                await store.dispatch('initStripe');

                await store.dispatch('checkWallets', ++tries);
            }
        },
        createPaymentElements: async (
            store,
            { amount, currency, type = 'recurring', tries = 1 },
        ) => {
            if (store.getters.isStripeLoaded) {
                await store.commit(
                    'setElements',
                    store.state.api.elements({
                        mode: type === 'recurring' ? 'subscription' : 'payment',
                        amount,
                        currency,
                        appearance: {
                            theme: 'flat',
                            variables: {
                                colorPrimary: '#ffc75a',
                                colorDanger: '#df1b41',
                                borderRadius: '4px',
                            },
                            labels: 'floating',
                        },
                        setup_future_usage: 'off_session',
                    }),
                );

                await store.commit(
                    'setPaymentElement',
                    store.state.elements.create('payment', {
                        layout: 'tabs',
                        paymentMethodOrder: [
                            'card',
                            'paypal',
                            'apple_pay',
                            'google_pay',
                        ],
                    }),
                );
            } else {
                await store.dispatch('initStripe');

                await store.dispatch('createPaymentElements', {
                    amount,
                    currency,
                    type,
                    tries,
                });

                tries++;
            }
        },
        getCustomerId: async (store, { name, email }) => {
            const response = await SecureApi().get(
                `/stripe/customers?name=${name}&email=${email}`,
            );

            if (response.data.success) {
                return response.data.data;
            } else {
                throw Error('Failed to fetch customerId');
            }
        },
        confirmPayment: async (
            store,
            { type, clientSecret, subscriptionId, userId, returnUrl },
        ) => {
            let _returnUrl = returnUrl;

            if (!_returnUrl) {
                if (window.location.hostname === 'localhost') {
                    _returnUrl = userId
                        ? `http://${window.location.hostname}:8080/buy-premium/${userId}`
                        : `http://${window.location.hostname}:8080/home-game/battle-pass?subscriptionId=${subscriptionId}`;
                } else {
                    _returnUrl = userId
                        ? `https://${window.location.hostname}/buy-premium/${userId}`
                        : `https://${window.location.hostname}/home-game/battle-pass?subscriptionId=${subscriptionId}`;
                }
            }

            return store.state.api[type]({
                clientSecret,
                elements: store.state.elements,
                confirmParams: { return_url: _returnUrl },
                redirect: 'always',
            });
        },
        createSubscription: async (
            store,
            { customerId, priceId, trialDays = 0, discount },
        ) => {
            const response = await SecureApi().post('/stripe/subscriptions', {
                customerId: customerId,
                priceId,
                trialDays,
                discount,
            });

            const { success, error, data } = response.data || {};

            if (success) {
                const subscription = data;

                return {
                    subscription,
                    confirmType: subscription?.latest_invoice?.payment_intent
                        ?.client_secret
                        ? 'confirmPayment'
                        : 'confirmSetup',
                    clientSecret:
                        subscription?.latest_invoice?.payment_intent
                            ?.client_secret ||
                        subscription?.pending_setup_intent?.client_secret,
                };
            } else if (error) {
                const errMsg =
                    typeof error?.message === 'string'
                        ? error?.message
                        : error?.message?.message;
                store.commit('setError', errMsg);

                throw new Error(errMsg);
            }
        },
        deleteSubscription: async (store, subscriptionId) => {
            try {
                const response = await SecureApi().patch(
                    `/stripe/subscriptions/delete/${subscriptionId}`,
                );

                if (response && response?.data?.error) {
                    throw new Error(response?.data?.error);
                }
            } catch (error) {
                console.error('[API] Cancel subscription error: ', error);
            }
        },
        subscribe: async (
            store,
            { name, email, priceInfo, trialDays, discount },
        ) => {
            try {
                const customerId = await store.dispatch('getCustomerId', {
                    name,
                    email,
                });

                await store.state.elements.submit();

                const subscribeResult = await store.dispatch(
                    'createSubscription',
                    {
                        customerId,
                        priceId: priceInfo.id,
                        trialDays,
                        discount,
                    },
                );

                const subscribeAttempt = {
                    paymentMethod: store.state.paymentMethodType,
                    cameFrom: sessionStorage.getItem('paymentOpenLocation'),
                    productName: store.rootGetters.user?.priceTestVersion?.name,
                    productPrice: priceInfo?.unit_amount / 100,
                    productInterval: priceInfo?.recurring?.interval,
                };

                sessionStorage.setItem(
                    'subscribeAttempt',
                    JSON.stringify(subscribeAttempt),
                );

                const confirmResult = await store.dispatch('confirmPayment', {
                    type: subscribeResult.confirmType,
                    clientSecret: subscribeResult.clientSecret,
                    subscriptionId: subscribeResult.subscription.id,
                });

                if (confirmResult?.error?.message) {
                    store.commit('setError', confirmResult?.error.message);

                    await store.dispatch(
                        'deleteSubscription',
                        subscribeResult.subscription.id,
                    );

                    new TrackingService().track(EVENTS.SUBSCRIBE_ATTEMPT, {
                        success: false,
                        ...subscribeAttempt,
                    });

                    sessionStorage.removeItem('subscribeAttempt');
                } else {
                    if (
                        discount &&
                        priceInfo?.recurring?.interval === 'month' &&
                        priceInfo?.recurring?.interval_count === 1
                    ) {
                        new TrackingService().track(EVENTS.COUPON_APPLIED);
                    }
                }
            } catch (error) {
                store.commit('setError', error.message);
            }
        },
        parentSubscribeForKid: async (
            store,
            { userId, name, email, priceInfo, trialDays },
        ) => {
            const subscribeAttempt = {
                paymentMethod: store.state.paymentMethodType,
                cameFrom: sessionStorage.getItem('paymentOpenLocation'),
                productName: store.rootGetters.user?.priceTestVersion?.name,
                productPrice: priceInfo?.unit_amount / 100,
                productInterval: priceInfo?.recurring?.interval,
            };

            sessionStorage.setItem(
                'subscribeAttempt',
                JSON.stringify(subscribeAttempt),
            );

            try {
                await store.state.elements.submit();

                const response = await SecureApi().post(
                    `/stripe/subscribe/${userId}`,
                    {
                        priceId: priceInfo.id,
                        name,
                        email,
                        trialDays,
                    },
                );

                if (response && response?.data?.success) {
                    const subscription = response.data.data;

                    new TrackingService().track(
                        EVENTS.PARENT_SUBSCRIBE_FOR_KID_ATTEMPT,
                        {
                            success: true,
                            ...subscribeAttempt,
                        },
                    );

                    const protocol =
                        window.location.hostname === 'localhost'
                            ? 'http'
                            : 'https';

                    const port =
                        window.location.hostname === 'localhost' ? ':8080' : '';

                    const returnUrl = `${protocol}://${window.location.hostname}${port}/premium-buy?subscriptionId=${subscription.id}`;

                    await store.dispatch('confirmPayment', {
                        type: subscription?.latest_invoice?.payment_intent
                            ?.client_secret
                            ? 'confirmPayment'
                            : 'confirmSetup',
                        clientSecret:
                            subscription?.latest_invoice?.payment_intent
                                ?.client_secret ||
                            subscription?.pending_setup_intent?.client_secret,
                        subscriptionId: subscription.id,
                        userId: userId,
                        returnUrl,
                    });
                } else if (response && response?.data?.error) {
                    store.commit(
                        'setError',
                        response.data.error?.message.message,
                    );

                    throw new Error(response.data.error.message.message);
                }
            } catch (error) {
                new TrackingService().track(
                    EVENTS.PARENT_SUBSCRIBE_FOR_KID_FAIL,
                    {
                        success: false,
                        ...subscribeAttempt,
                    },
                );

                store.commit('setError', error.message);
            }
        },
        publicSubscribe: async (
            store,
            { userId, name, email, priceInfo, trialDays, discount },
        ) => {
            try {
                await store.state.elements.submit();

                const response = await SecureApi().post(
                    `/stripe/subscribe/${userId}`,
                    {
                        priceId: priceInfo.id,
                        name,
                        email,
                        trialDays,
                        discount,
                    },
                );

                if (response && response?.data?.success) {
                    const subscription = response.data.data;

                    new AnonymousTrackingService().track(
                        ANONYMOUSEVENTS.PARENT_TRIES_TO_BUY_SUBSCRIPTION,
                    );

                    await store.dispatch('confirmPayment', {
                        type: subscription?.latest_invoice?.payment_intent
                            ?.client_secret
                            ? 'confirmPayment'
                            : 'confirmSetup',
                        clientSecret:
                            subscription?.latest_invoice?.payment_intent
                                ?.client_secret ||
                            subscription?.pending_setup_intent?.client_secret,
                        subscriptionId: subscription.id,
                        userId: userId,
                    });
                } else if (response && response?.data?.error) {
                    store.commit(
                        'setError',
                        response.data.error?.message.message,
                    );

                    throw new Error(response.data.error.message.message);
                }
            } catch (error) {
                new AnonymousTrackingService().track(
                    ANONYMOUSEVENTS.PARENT_FAILS_TO_BUY_SUBSCRIPTION,
                );

                store.commit('setError', error.message);
            }
        },
        pay: async (store, { priceInfo, location, name, email }) => {
            const price = (priceInfo.unit_amount / 100).toFixed(2);

            const amount = priceInfo?.metadata?.itemsAmount;

            const productType = priceInfo?.metadata?.productType;

            const paymentAttempt = {
                paymentMethod: store.state.paymentMethodType,
                cameFrom: sessionStorage.getItem('oneTimePaymentOpenLocation'),
                productName: priceInfo?.nickname,
                productPrice: price,
                productAmount: amount,
                productType,
            };

            sessionStorage.setItem(
                'paymentAttempt',
                JSON.stringify(paymentAttempt),
            );

            try {
                await store.state.elements.submit();

                // for mobile InApp purchases this comes from external server, e.g. revenuecat

                const response = await SecureApi().post(
                    '/stripe/payment/init',
                    { priceId: priceInfo.id, name, email },
                );

                const { success, error, data } = response.data || {};

                if (success) {
                    const paymentIntent = data;

                    const protocol =
                        window.location.hostname === 'localhost'
                            ? 'http'
                            : 'https';

                    const port =
                        window.location.hostname === 'localhost' ? ':8080' : '';

                    const returnUrl = priceInfo?.metadata?.lifetimeSubscription
                        ? null
                        : `${protocol}://${window.location.hostname}${port}/home-game/pay/${priceInfo.id}?price=${price}&purchase=true&productType=${productType}&amount=${amount}&location=${location}`;

                    await store.dispatch('confirmPayment', {
                        type: 'confirmPayment',
                        clientSecret: paymentIntent.client_secret,
                        returnUrl,
                    });
                } else if (error) {
                    new TrackingService().track(EVENTS.ATTEMPTED_TO_BUY, {
                        success: false,
                        ...paymentAttempt,
                    });

                    sessionStorage.removeItem('paymentAttempt');

                    const errMsg =
                        typeof error?.message === 'string'
                            ? error?.message
                            : error?.message?.message;

                    throw new Error(errMsg);
                }
            } catch (error) {
                store.commit('setError', error.message);
            }
        },
        checkPaymentRedirect: async (store, { route, emitter }) => {
            console.debug('checkPaymentRedirect');

            if (route?.query?.subscriptionId && route?.query?.redirect_status) {
                await fetchAndUpdateUser();

                emitter.emit('homeGamePaymentModalSubscribed');

                try {
                    const success =
                        route?.query?.redirect_status === 'succeeded';

                    const subscribeAttempt = JSON.parse(
                        sessionStorage.getItem('subscribeAttempt') || 'null',
                    );

                    subscribeAttempt &&
                        new TrackingService().track(EVENTS.SUBSCRIBE_ATTEMPT, {
                            success,
                            ...subscribeAttempt,
                        });
                    Hotjar.tagRecording(['Subscription attempt']);
                    subscribeAttempt &&
                        success &&
                        Hotjar.tagRecording(['Subscription success']);
                    sessionStorage.removeItem('subscribeAttempt');

                    emitter?.emit('openHomeGamePaymentModal', 'stripeRedirect');

                    if (!success) {
                        store.commit(
                            'setError',
                            'Something went wrong during last payment' +
                                ' attempt. Please try again or use another method.',
                        );
                    }
                } catch (err) {
                    console.error('checkPaymentSetup: ', err);
                }
            }

            if (route?.query?.purchase) {
                const success = route?.query?.redirect_status === 'succeeded';

                const paymentAttempt = JSON.parse(
                    sessionStorage.getItem('paymentAttempt') || 'null',
                );

                sessionStorage.removeItem('paymentAttempt');

                paymentAttempt &&
                    new TrackingService().track(EVENTS.ATTEMPTED_TO_BUY, {
                        success,
                        ...paymentAttempt,
                    });
                if (route?.query?.redirect_status === 'failed') {
                    try {
                        const { error } =
                            await store.state.api.retrievePaymentIntent(
                                route?.query?.payment_intent_client_secret,
                            );

                        if (error?.message)
                            store.commit('setError', error?.message);

                        store.commit(
                            'setError',
                            'Something went wrong during payment. Please try again or use another method.',
                        );
                    } catch (err) {
                        console.error('checkPaymentSetup: ', err);
                    }
                }
                if (route?.query?.redirect_status === 'succeeded') {
                    app.config.globalProperties.$emitter.emit(
                        'openAfterPurchaseModal',
                        route.query,
                    );
                }
            }
        },
        fetchItemsPrices: async (store, productType = 'mbucks') => {
            try {
                const response = await SecureApi().get(
                    `/payments/prices/${productType}`,
                );

                if (response && response?.data?.success) {
                    return response.data.data;
                } else if (response && response?.data?.error) {
                    throw new Error(response.data.error.message.message);
                }
            } catch (err) {
                console.error('fetchItemsPrices: ', err);
            }
        },
        sendPurchasesCooldownEmail: async (store, email) => {
            try {
                const response = await SecureApi().post(
                    'payments/purchases-lock/send-code',
                    { email },
                );
                const { success, data, error } = response.data;

                if (success && data) {
                    store.dispatch(
                        'v2/user/update',
                        { paymentInfo: data },
                        { root: true },
                    );
                    return data;
                } else if (error) {
                    return { error: error.message };
                }
            } catch (err) {
                console.error('sendPurchasesCooldownEmail: ', err);
            }
        },
        unlockPurchasesCooldown: async (store, code) => {
            try {
                const response = await SecureApi().post(
                    'payments/purchases-lock/verify-code',
                    { code },
                );
                const { success, data, error } = response.data;

                if (success && data) {
                    store.dispatch(
                        'v2/user/update',
                        { paymentInfo: data },
                        { root: true },
                    );
                    return data;
                } else if (error) {
                    return { error: error.message };
                }
            } catch (err) {
                console.error('unlockPurchasesCooldown: ', err);
            }
        },
    },
};
