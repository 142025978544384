import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1101",
  height: "700",
  viewBox: "0 0 1101 700",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M825.077 335.571C836.589 371.626 700.345 415 548.945 415C397.545 415 327.585 371.83 276.723 343.148C276.723 331.383 266.556 299.107 282.338 297.014C336 296.18 446.86 270 552.089 270C671.159 270 797.456 274.576 823.763 292.437C833.664 301.108 821.386 324.046 825.077 335.571Z\" fill=\"#F8F8F8\" stroke=\"black\" stroke-width=\"12\"></path><path opacity=\"0.4\" d=\"M395.5 319.532C388 323.055 374.932 325.053 342.881 323.055C310.529 321.058 267.807 312.354 274.128 311.581C285.547 310.168 328.461 309 360.963 309C384 311.5 386.5 314.5 395.5 319.532Z\" fill=\"black\"></path><path opacity=\"0.4\" d=\"M334.131 340.745C306.801 338.747 270.706 330.039 276.027 329.266C285.66 327.853 320.091 332.527 347.538 332.527C371 336 361.202 342.724 334.131 340.745Z\" fill=\"black\"></path><path opacity=\"0.4\" d=\"M577.131 282.745C549.801 280.747 513.706 272.039 519.027 271.266C528.66 269.853 563.091 274.527 590.538 274.527C614 278 604.202 284.724 577.131 282.745Z\" fill=\"black\"></path><path opacity=\"0.4\" d=\"M826.5 312.83C826.5 316.892 806.94 317.983 776.48 316.118C745.714 314.254 705.086 306.13 711.097 305.409C721.957 304.09 762.768 303 793.656 303C824.544 303 826.5 308.768 826.5 312.83Z\" fill=\"black\"></path><path opacity=\"0.4\" d=\"M609.5 391.83C598 391.83 593 395.118 559.48 395.118C528.714 393.254 488.086 385.13 494.097 384.409C504.957 383.09 545.768 382 576.656 382C600 384.409 601.5 386.5 609.5 391.83Z\" fill=\"black\"></path><path opacity=\"0.4\" d=\"M801.37 333.745C778.124 331.747 747.423 323.039 751.948 322.266C760.142 320.853 789.428 325.527 812.773 325.527C836.119 325.527 824.396 335.724 801.37 333.745Z\" fill=\"black\"></path><circle id=\"anchor-eyewear_10\" cx=\"551.5\" cy=\"350\" r=\"10\"></circle>", 8)
  ])))
}
export default { render: render }