import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M229.025 319.05C267.146 319.05 298.05 288.146 298.05 250.025C298.05 211.904 267.146 181 229.025 181C190.904 181 160 211.904 160 250.025C160 288.146 190.904 319.05 229.025 319.05Z\" fill=\"#240B4A\"></path><path d=\"M264.283 269.411C247.387 275.974 230.718 275.115 214.305 267.587C194.368 258.441 178.548 240.565 160.039 250.811C160.466 288.565 191.167 319.045 229.025 319.045C265.703 319.045 295.617 290.412 297.832 254.288C286.582 259.597 275.41 265.091 264.283 269.411Z\" fill=\"#240B4A\"></path><path d=\"M275.3 223.493C275.3 237.251 264.149 248.407 250.386 248.407C236.628 248.407 225.473 237.251 225.473 223.493C225.473 209.734 236.628 198.579 250.386 198.579C264.149 198.583 275.3 209.734 275.3 223.493Z\" fill=\"white\"></path><path d=\"M207.213 274.907C207.213 282.265 201.247 288.231 193.89 288.231C186.532 288.231 180.566 282.265 180.566 274.907C180.566 267.55 186.532 261.584 193.89 261.584C201.247 261.58 207.213 267.546 207.213 274.907Z\" fill=\"white\"></path><path d=\"M204.818 296.013C204.818 291.713 208.303 288.228 212.603 288.228C216.903 288.228 220.388 291.713 220.388 296.013C220.388 300.313 216.903 303.798 212.603 303.798C208.303 303.798 204.818 300.313 204.818 296.013Z\" fill=\"white\"></path><path d=\"M404 248.927C404 286.391 416.319 217.578 468.103 217.578C519.883 217.578 540 286.391 540 248.927C540 211.463 509.556 181.094 472 181.094C434.444 181.094 404 211.463 404 248.927Z\" fill=\"#240B4A\"></path><circle id=\"anchor-eyes_10\" cx=\"350\" cy=\"250\" r=\"10\"></circle>", 7)
  ])))
}
export default { render: render }