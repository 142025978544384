import { randomIntFromRange } from '@/core/helpers/utils';

export const isCorrectFractionAnswer = (answer, question) => {
    // console.log('ISFN1', answer, question);

    if (!answer && answer !== 0) {
        return false;
    }

    const intPlayerWholeNumber = parseInt(answer.wholeNumber);

    const intPlayerDenominator = parseInt(answer.denominator);

    const intPlayerNumerator = parseInt(answer.numerator);

    if (intPlayerDenominator === 0) return false;

    // Expected result is 0
    if (question.wholeNumber === 0 && question.numerator === 0) {
        return (
            intPlayerWholeNumber === 0 &&
            intPlayerDenominator !== 0 &&
            !intPlayerNumerator
        );
    }

    const isMixedToImproper =
        question.subtopic === 'mixedNumberToImproperFraction';

    const isImproperFraction = question.numberType === 'improperFraction';

    // Answered whole number must exactly match with what is expected
    if (
        !isMixedToImproper &&
        !isImproperFraction &&
        question.wholeNumber &&
        intPlayerWholeNumber !== question.wholeNumber
    ) {
        return false;
    }

    // case when fraction operation result is whole number
    if (!question.numerator && !answer.numerator && !answer.denominator) {
        return question.wholeNumber === parseInt(answer.wholeNumber);
    }

    if (isMixedToImproper) {
        const expected =
            question.wholeNumber * question.denominator + question.numerator;

        const condition1 =
            intPlayerNumerator === expected &&
            intPlayerDenominator === question.denominator;

        // intPlayerNumerator and intPlayerDenominator can be
        // either lower or higher than what is expected in question
        const condition2 =
            (intPlayerNumerator % expected === 0 &&
                intPlayerDenominator % question.denominator === 0) ||
            (expected % intPlayerNumerator === 0 &&
                question.denominator % intPlayerDenominator);

        const condition3 =
            intPlayerNumerator / intPlayerDenominator ===
            expected / question.denominator;

        return condition1 || (condition2 && condition3);
    }

    // intPlayerDenominator can be either lower or higher than
    // question.denominator
    const reductionCondition1 =
        question.denominator % intPlayerDenominator === 0 ||
        intPlayerDenominator % question.denominator === 0;

    // intPlayerAnswer can be either lower or higher than
    // question.numerator or question.remainder
    const reductionCondition2 =
        question.numerator % intPlayerNumerator === 0 ||
        intPlayerNumerator % question.numerator === 0 ||
        question.remainder % intPlayerNumerator === 0 ||
        intPlayerNumerator % question.remainder === 0;

    const reductionCondition3 =
        question.denominator / intPlayerDenominator ===
            question.numerator / intPlayerNumerator ||
        question.denominator / intPlayerDenominator ===
            question.remainder / intPlayerNumerator;

    // Check if the fraction is reducted.
    return !!(
        reductionCondition1 &&
        reductionCondition2 &&
        reductionCondition3
    );
};

export const getFractionLayout = (
    fraction,
    isWrongAnswer = false,
    isEqualMark = false,
    calledIn,
) => {
    const wholeNumber = fraction?.wholeNumber || 0;

    const equalMark = isEqualMark ? ' = ' : '';

    if (!fraction?.numerator)
        return (
            equalMark +
            `<div class="${
                isWrongAnswer ? 'wrong-answer' : ''
            }">${wholeNumber.toString()}</div>`
        );

    if (isWrongAnswer) {
        return (
            equalMark +
            `<div class="mixed-fraction wrong-answer">
                        <div class="whole-number">${wholeNumber || ''}</div>
                        <div class="fraction">
                            <div class="fraction__numerator">${
                                fraction?.numerator
                            }</div>
                            <div class="fraction__separator"></div>
                            <div class="fraction__denominator">${
                                fraction?.denominator
                            }</div>
                        </div>
                    </div>`
        );
    }

    const separator = `fraction__separator${
        calledIn === 'inGame' ? '-white' : ''
    }`;

    return (
        equalMark +
        `<div class="mixed-fraction">
                      <div class="whole-number">${wholeNumber || ''}</div>
                        <div class="improper-fraction">
                          <div class="fraction__numerator">${
                              fraction?.numerator
                          }</div>
                          <div class="${separator}"></div>
                          <div class="fraction__denominator">${
                              fraction?.denominator
                          }</div>
                        </div>
                      </div>`
    );
};

const getNumbersGreatestCommonDivisor = (a, b) => {
    if (a === 0) {
        return b;
    }

    return getNumbersGreatestCommonDivisor(b % a, a);
};

const getReducedFraction = (num, den) => {
    let commonFactor = getNumbersGreatestCommonDivisor(num, den);

    return {
        num: num / commonFactor,
        den: den / commonFactor,
    };
};

const getImproperNumerator = ({ num, den, wholeNumber }) => {
    return wholeNumber * den + num;
};

const getProperFraction = ({ num, den }) => ({
    wholeNumber: Math.floor(num / den),
    numerator: num % den,
    denominator: den,
});

const getNumbersList = (minNumber, maxNumber) => {
    const length = maxNumber - minNumber + 1;

    return Array.from({ length }, (_, i) => minNumber + i);
};

const getDenominator = (denominatorList) => {
    return denominatorList[randomIntFromRange(0, denominatorList.length - 1)];
};

const getLesserNumber = (num1, num2) => {
    return num1 > num2 ? num2 : num1;
};

export class FractionsOperations {
    constructor(props) {
        this.operation = props.operation;

        this.wholeNumberMin = props.wholeNumberMin || 0;

        this.wholeNumberMax = props.wholeNumberMax || 0;

        this.includeWholeNumbers = props.includeWholeNumbers;

        this.fixedFirstWholeNumber = props.fixedFirstWholeNumber;

        this.fixedSecondWholeNumber = props.fixedSecondWholeNumber;

        this.isWholeFirstNumber = props.isWholeFirstNumber;

        this.isWholeSecondNumber = props.isWholeSecondNumber;

        this.denominatorParametersSelected =
            props.denominatorParametersSelected || '';

        this.denominatorMin = props.denominatorMin || 0;

        this.denominatorMax = props.denominatorMax || 0;

        this.denominatorList = props.denominatorList || [];

        this.numeratorParametersSelected =
            props.numeratorParametersSelected || '';

        this.numeratorMin = props.numeratorMin || 0;

        this.numeratorMax = props.numeratorMax || 0;

        this.w1 = 0;
        this.w2 = 0;
        this.d1 = 0;
        this.d2 = 0;
        this.n1 = 0;
        this.n2 = 0;
    }

    getDenominators() {
        let reducibleFactorArray = [];

        let reducibleFactor = 0;

        let reducibleFactorMax = 0;

        let reducibleFactorIndex = 0;

        if (this.denominatorParametersSelected === 'denominatorFixed') {
            this.d1 = this.denominatorMin;

            this.d2 = this.denominatorMax;
        }

        if (this.denominatorParametersSelected === 'denominatorLike') {
            const denominatorList = this.denominatorList.length
                ? this.denominatorList
                : getNumbersList(this.denominatorMin, this.denominatorMax);

            this.d1 = getDenominator(
                denominatorList,
                this.denominatorMin,
                this.denominatorMax,
            );

            this.d2 = this.d1;
        }

        if (
            this.denominatorParametersSelected ===
            'denominatorUnlikeSelectedInList'
        ) {
            const denominatorList = this.denominatorList.length
                ? this.denominatorList
                : getNumbersList(this.denominatorMin, this.denominatorMax);

            this.d1 = getDenominator(denominatorList);

            const filteredDenominatorList = denominatorList.filter(
                (item) => item !== this.d1,
            );

            this.d2 = getDenominator(filteredDenominatorList);
        }

        if (
            this.denominatorParametersSelected === 'denominatorUnlikeReducible'
        ) {
            this.d1 = randomIntFromRange(2, this.denominatorMax / 2);

            reducibleFactorMax = Number.parseInt(this.denominatorMax / this.d1);

            let i = reducibleFactorMax;

            while (i >= 2) {
                reducibleFactorArray.push(i);
                i--;
            }

            reducibleFactorIndex = Math.floor(
                Math.random() * reducibleFactorArray.length,
            );

            reducibleFactor = reducibleFactorArray[reducibleFactorIndex];

            this.d2 = this.d1 * reducibleFactor;
        }

        if (
            this.denominatorParametersSelected ===
            'denominatorUnlikeIrreducible'
        ) {
            this.d1 = randomIntFromRange(
                this.denominatorMin,
                this.denominatorMax,
            );

            this.d2 = randomIntFromRange(
                this.denominatorMin,
                this.denominatorMax,
            );

            while (
                this.d2 === this.d1 ||
                this.d1 % this.d2 === 0 ||
                this.d2 % this.d1 === 0
            ) {
                this.d2 = randomIntFromRange(
                    this.denominatorMin,
                    this.denominatorMax,
                );
            }
        }

        if (this.isWholeFirstNumber) {
            this.d1 = 1;
        }

        if (this.isWholeSecondNumber) {
            this.d2 = 1;
        }

        return { d1: this.d1, d2: this.d2 };
    }

    getNominators() {
        if (
            this.numeratorParametersSelected ===
            'numeratorPreventImproperFractions'
        ) {
            this.n1 = randomIntFromRange(
                this.numeratorMin,
                getLesserNumber(this.numeratorMax, this.d1 - 2),
            );

            this.n2 = randomIntFromRange(
                this.numeratorMin,
                getLesserNumber(this.numeratorMax, this.d2 - 2),
            );
        }

        if (
            this.numeratorParametersSelected ===
            'numeratorAllowImproperFractions'
        ) {
            this.n1 = randomIntFromRange(this.numeratorMin, this.numeratorMax);
            this.n2 = randomIntFromRange(this.numeratorMin, this.numeratorMax);
        }

        if (this.isWholeFirstNumber) {
            this.n1 = 0;
        }

        if (this.isWholeSecondNumber) {
            this.n2 = 0;
        }

        return { n1: this.n1, n2: this.n2 };
    }

    getWholeNumber() {
        if (!this.includeWholeNumbers) {
            return { w1: 0, w2: 0 };
        }

        if (this.fixedFirstWholeNumber !== undefined) {
            this.w2 = this.fixedFirstWholeNumber;
        } else {
            this.w1 = randomIntFromRange(
                this.wholeNumberMin,
                this.wholeNumberMax,
            );
        }

        if (this.fixedSecondWholeNumber !== undefined) {
            this.w2 = this.fixedSecondWholeNumber;
        } else if (this.operation === 'Subtract') {
            this.w2 = randomIntFromRange(this.wholeNumberMin, this.w1 - 1);
        } else {
            this.w2 = randomIntFromRange(
                this.wholeNumberMin,
                this.wholeNumberMax,
            );
        }
        return { w1: this.w1, w2: this.w2 };
    }

    getFractionAdditionAnswer(fraction1, fraction2) {
        const { n1, d1, w1 } = fraction1;

        const { n2, d2, w2 } = fraction2;

        const improverN1 = getImproperNumerator({
            num: n1,
            den: d1,
            wholeNumber: w1,
        });

        const improverN2 = getImproperNumerator({
            num: n2,
            den: d2,
            wholeNumber: w2,
        });

        // Greatest common divisor
        let gcd = getNumbersGreatestCommonDivisor(d1, d2);

        // Least common multiple
        let lcm = (d1 * d2) / gcd;

        let numerator = improverN1 * (lcm / d1) + improverN2 * (lcm / d2);

        const reducedFraction = getReducedFraction(numerator, lcm);

        return getProperFraction(reducedFraction);
    }

    avoidNegativeSubtractResult = (fraction1, fraction2) => {
        const answer = this.getFractionSubtractAnswer(fraction1, fraction2);

        return answer.wholeNumber < 0
            ? {
                  subtractNumber1: fraction2,
                  subtractNumber2: fraction1,
              }
            : {
                  subtractNumber1: fraction1,
                  subtractNumber2: fraction2,
              };
    };

    // It's need for subtract mixed numbers
    avoidNegativeFractionPartResult = (subtractNumber1, subtractNumber2) => {
        const answer = this.getFractionSubtractAnswer(
            { ...subtractNumber1, wholeNumber: 0 },
            { ...subtractNumber2, wholeNumber: 0 },
        );

        if (answer.wholeNumber < 0) {
            return {
                fractionNumber1: {
                    wholeNumber: subtractNumber1.wholeNumber,
                    numerator: subtractNumber2.numerator,
                    denominator: subtractNumber2.denominator,
                },
                fractionNumber2: {
                    wholeNumber: subtractNumber2.wholeNumber,
                    numerator: subtractNumber1.numerator,
                    denominator: subtractNumber1.denominator,
                },
            };
        }

        return {
            fractionNumber1: subtractNumber1,
            fractionNumber2: subtractNumber2,
        };
    };

    getFractionSubtractAnswer(fraction1, fraction2) {
        const { numerator: n1, denominator: d1, wholeNumber: w1 } = fraction1;

        const { numerator: n2, denominator: d2, wholeNumber: w2 } = fraction2;

        const improverN1 = getImproperNumerator({
            num: n1,
            den: d1,
            wholeNumber: w1,
        });

        const improverN2 = getImproperNumerator({
            num: n2,
            den: d2,
            wholeNumber: w2,
        });

        // Greatest common divisor
        let gcd = getNumbersGreatestCommonDivisor(d1, d2);

        // Least common multiple
        let lcm = (d1 * d2) / gcd;

        let numerator = improverN1 * (lcm / d1) - improverN2 * (lcm / d2);

        const reducedFraction = getReducedFraction(numerator, lcm);

        return getProperFraction(reducedFraction);
    }

    getFractionMultiplicationAnswer(fraction1, fraction2) {
        const { n1, d1, w1 } = fraction1;

        const { n2, d2, w2 } = fraction2;

        const improverN1 = getImproperNumerator({
            num: n1,
            den: d1,
            wholeNumber: w1,
        });

        const improverN2 = getImproperNumerator({
            num: n2,
            den: d2,
            wholeNumber: w2,
        });

        const num = improverN1 * improverN2;

        const den = d1 * d2;

        const reducedFraction = getReducedFraction(num, den);

        return getProperFraction(reducedFraction);
    }

    getFractionDividingAnswer(fraction1, fraction2) {
        const { n1, d1, w1 } = fraction1;

        const { n2, d2, w2 } = fraction2;

        const improverN1 = getImproperNumerator({
            num: n1,
            den: d1,
            wholeNumber: w1,
        });

        const improverN2 = getImproperNumerator({
            num: n2,
            den: d2,
            wholeNumber: w2,
        });

        const num = improverN1 * d2;

        const den = improverN2 * d1;

        const reducedFraction = getReducedFraction(num, den);

        return getProperFraction(reducedFraction);
    }
}
