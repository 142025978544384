import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M298.758 124.394C288.129 142.028 296.038 164.736 315.217 172.005C333.168 178.806 353.02 167.48 357.77 149.276C352.889 149.912 348.008 150.552 343.127 151.188C347.203 159.633 353.657 167.57 362.4 171.434C371.903 175.635 382.943 173.941 391.275 167.874C399.452 161.922 405.191 152.771 405.927 142.578C406.683 132.106 401.51 121.568 394.188 114.332C386.85 107.084 375.515 118.389 382.857 125.645C391.399 134.087 392.676 147.283 382.167 154.793C371.074 162.718 361.731 152.996 356.965 143.119C353.916 136.806 343.977 138.693 342.322 145.031C339.84 154.535 330.214 160.462 320.69 157.029C310.468 153.345 307.169 141.487 312.596 132.479C317.925 123.623 304.075 115.571 298.758 124.394Z",
      fill: "#282828"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_16",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }