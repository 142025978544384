import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M187 275C187 285.5 202.554 285.5 202.554 296.001C202.554 306.501 187 306.501 187 317.004C187 327.505 202.554 327.505 202.554 338.002C202.554 348.502 187 348.502 187 359H261.446C261.446 348.5 277 348.5 277 338.002C277 327.502 261.446 327.502 261.446 317.004C261.446 306.504 277 306.504 277 296.001C277 285.5 261.446 285.5 261.446 275H187Z\" fill=\"#97E2FF\"></path><path d=\"M307 214.213C307 171.963 288.757 262.72 230.5 262.72C172.247 262.72 154 171.963 154 214.213C154 256.464 188.249 290.713 230.5 290.713C272.751 290.713 307 256.464 307 214.213Z\" fill=\"black\"></path><path d=\"M431 275C431 285.5 446.554 285.5 446.554 296.001C446.554 306.501 431 306.501 431 317.004C431 327.505 446.554 327.505 446.554 338.002C446.554 348.502 431 348.502 431 359H505.446C505.446 348.5 521 348.5 521 338.002C521 327.502 505.446 327.502 505.446 317.004C505.446 306.504 521 306.504 521 296.001C521 285.5 505.446 285.5 505.446 275H431Z\" fill=\"#97E2FF\"></path><path d=\"M547 214.213C547 171.963 528.757 262.72 470.5 262.72C412.247 262.72 394 171.963 394 214.213C394 256.464 428.249 290.713 470.5 290.713C512.751 290.713 547 256.464 547 214.213Z\" fill=\"black\"></path><circle id=\"anchor-eyes_21\" cx=\"350\" cy=\"250\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }