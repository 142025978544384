import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M436.307 120.26C445.819 113.069 455.714 106.757 465.95 101.351L463.524 102.578C475.544 96.4882 488.245 92.244 501.741 91.5466L499.022 91.6815C502.372 91.5425 505.755 91.5266 509.098 91.3234L506.378 91.4583C516.561 90.9797 526.921 91.5701 537.387 93.2254C542.792 94.0694 547.401 87.6893 546.388 79.27C545.966 75.1656 544.496 71.088 542.288 67.896C540.08 64.7041 537.307 62.6469 534.553 62.1577C522.305 60.3148 510.197 59.8219 498.329 60.683L501.048 60.5481C489.58 60.3926 478.463 62.2919 467.927 66.2071C457.478 70.2111 447.418 75.414 437.824 81.7765C432.859 84.9704 428.002 88.4156 423.194 91.9632C418.8 95.202 417.856 105.366 421.5 112.701C423.263 116.405 425.789 119.266 428.544 120.681C431.3 122.096 434.07 121.954 436.273 120.286L436.307 120.26Z",
      fill: "#1B1A1A"
    }, null, -1),
    _createElementVNode("path", {
      d: "M219.016 95.2303C229.478 93.4799 239.835 92.8674 250.003 93.3979L247.268 93.2545C264.773 94.288 281.566 98.8319 297.214 106.769L294.77 105.541C303.888 110.204 312.626 115.882 320.92 122.535C325.21 125.977 332.425 122.785 335.75 115.047C337.432 111.311 338.188 107.074 337.857 103.228C337.526 99.3831 336.134 96.2299 333.975 94.4329C321.571 84.5298 308.225 76.7308 294.132 71.1496C271.42 62.5051 246.913 60.2095 221.699 64.3649C218.963 64.8197 216.208 66.7701 213.979 69.8282C211.725 73.0464 210.242 77.1803 209.855 81.3304C209.368 89.5668 213.081 96.1776 218.954 95.2299L219.016 95.2303Z",
      fill: "#1B1A1A"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_2",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }