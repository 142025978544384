import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M300.666 239.624C261.445 225.145 221.485 213.12 180.975 203.632C168.799 200.78 165.669 223.133 177.785 225.972C204.296 232.181 230.529 239.533 256.476 247.971C228.406 253.367 200.078 256.382 171.806 256.749C166.551 256.817 161.549 259.937 160.605 266.547C159.833 271.958 163.323 279.158 168.616 279.088C211.918 278.525 255.038 272.76 297.59 261.166C306.068 258.856 310.561 243.278 300.666 239.624Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("path", {
      d: "M399.491 239.624C438.711 225.145 478.671 213.12 519.182 203.632C531.357 200.78 534.488 223.133 522.372 225.972C495.86 232.181 469.627 239.533 443.68 247.971C471.75 253.367 500.078 256.382 528.35 256.749C533.605 256.817 538.607 259.937 539.551 266.547C540.324 271.958 536.834 279.158 531.54 279.088C488.238 278.525 445.119 272.76 402.567 261.166C394.089 258.856 389.595 243.278 399.491 239.624Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-eyes_13",
      cx: "350",
      cy: "250",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }