import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1101",
  height: "700",
  viewBox: "0 0 1101 700",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g opacity=\"0.5\"><path d=\"M354.939 279.078L494.592 418.73L529.054 374.75L401.261 244.715L354.939 279.078Z\" fill=\"#FFFFFA\"></path></g><g opacity=\"0.5\"><path d=\"M479.402 429.876L342.644 293.117L332.986 361.496L371.209 418.729L421.447 439.35L479.402 429.876Z\" fill=\"#FFFFFA\"></path></g><path d=\"M312.5 357C316.206 421.099 320.059 552.805 290.47 587.029C286.395 591.76 281.712 594.692 276.422 595.383C271.915 595.976 267.652 593.996 264.23 591.188C268.002 583.501 291.503 565.501 299.003 556.001C318.74 531 311.01 509.371 308.785 496.13C297.501 429 298.255 368.4 290.47 303H277.97C285.586 389.112 282.092 445.715 297.501 514C306.147 552.312 233.546 586.726 248.502 599C256.104 605.253 273.834 610.258 283 608C287.994 606.853 302.692 605.5 307.5 593C335 521.5 331.595 429.901 328 359.481L312.5 357Z\" fill=\"#ECC024\" stroke=\"black\" stroke-width=\"6\" stroke-linejoin=\"round\"></path><path d=\"M432.61 235C376.933 235 329.651 279.888 326.695 335.445C318.428 332.857 309.666 339.522 309.666 348.196C309.666 357.318 319.79 364 328.292 360.387C337.301 409.813 382.713 447.14 432.61 447.14C490.438 447.14 538.68 398.898 538.68 341.07C538.68 283.258 490.422 235 432.61 235ZM432.61 418.5C382.716 418.5 352 390.963 352 341.07C352 291.399 383.413 265 432.61 265C482.497 265 512.5 291.182 512.5 341.07C512.5 387.924 480.644 418.5 432.61 418.5Z\" fill=\"#ECC024\" stroke=\"black\" stroke-width=\"10\"></path><circle id=\"anchor-eyewear_6\" cx=\"551.5\" cy=\"350\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }