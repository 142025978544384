import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M743.5 779.438C731.745 803.438 657 781.438 595.5 740.938C511.5 647.438 528.867 581.757 507 530.938\" fill=\"black\" fill-opacity=\"0.25\"></path><path class=\"hairColor\" d=\"M501 385.203C349.125 385.203 226 519.87 226 686.003V903.703L301.612 618.203L274.682 570.703L501 502.203L695.727 517.703L669.832 570.703L776 903.703V686.003C776 519.87 652.875 385.203 501 385.203Z\" fill=\"#FEDC58\" stroke=\"#1B1A1A\" stroke-width=\"12\" stroke-miterlimit=\"10\" stroke-linejoin=\"round\"></path><path class=\"hairColor\" d=\"M716.659 535.203C676.917 444.913 588.536 393.203 493.114 393.203C373.285 393.203 261.567 488.203 241.637 616.943L274.5 693.438C294.5 664.438 441.5 549.203 518 535.203C571.5 644.938 558.5 755.438 736.5 774.938C766.917 771.394 691.152 564.145 716.659 535.203Z\" fill=\"#FEDC58\"></path><path d=\"M493.114 402.633C477.184 403.82 461.353 406.27 445.745 409.963L434.164 413.013C430.327 414.143 426.528 415.383 422.71 416.563L411.393 420.563C407.657 422.023 403.967 423.563 400.249 425.103C385.468 431.46 371.155 439.052 357.438 447.813C343.677 456.688 330.479 466.571 317.934 477.393C329.151 464.696 341.65 453.437 355.191 443.833C368.743 434.133 383.172 425.987 398.249 419.523C402.058 418.023 405.848 416.463 409.666 415.013C413.539 413.723 417.375 412.313 421.266 411.113C425.157 409.913 429.103 408.923 433.04 407.903C436.977 406.883 440.968 406.143 444.914 405.373C460.827 402.388 477.005 401.469 493.114 402.633Z\" fill=\"black\" fill-opacity=\"0.25\"></path><path d=\"M278.5 691.938C388 573.438 467 558.438 520.5 540.438C566.5 610.438 541.5 750.938 734.5 778.438\" stroke=\"#592D3D\" stroke-width=\"12\" stroke-miterlimit=\"10\"></path><circle id=\"anchor-hair-front_13\" cx=\"501\" cy=\"672.369\" r=\"10\"></circle>", 6)
  ])))
}
export default { render: render }