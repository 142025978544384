import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M191.673 249.579C214.493 243.68 237.709 239.965 261.238 238.546C259.407 234.126 257.575 229.707 255.744 225.287C250.885 229.146 244.725 231.126 238.986 233.246C231.923 235.856 224.858 238.463 217.793 241.072C203.664 246.29 189.536 251.507 175.407 256.725C166.118 260.155 169.649 273.942 179.539 271.703C209.306 264.963 239.519 260.935 270.013 259.638C268.705 254.814 267.398 249.989 266.091 245.165C242.579 257.737 214.752 261.94 189.07 268.133C184.96 269.123 182.598 273.725 183.643 277.687C184.829 282.185 189.063 283.671 193.202 283.111C206.379 281.329 219.558 279.547 232.736 277.765C238.978 276.92 245.221 276.077 251.463 275.232C257.98 274.351 264.769 272.792 271.337 272.676C270.03 272.322 268.722 271.969 267.415 271.615C267.707 271.788 268.001 271.962 268.293 272.135C267.011 269.899 265.727 267.664 264.444 265.428C264.429 265.638 264.414 265.849 264.399 266.059C265.682 263.824 266.964 261.589 268.248 259.353C268.072 259.483 267.896 259.612 267.721 259.741C270.336 259.741 272.951 259.741 275.565 259.741C275.422 259.657 275.278 259.572 275.135 259.487C266.509 254.383 258.683 267.804 267.292 272.9C267.435 272.984 267.578 273.068 267.721 273.154C269.917 274.453 273.4 274.746 275.565 273.154C278.433 271.044 279.724 269.034 279.986 265.428C280.181 262.746 278.365 260.039 276.137 258.722C270.406 255.335 261.87 258.289 255.655 259.128C248.372 260.113 241.089 261.098 233.807 262.083C218.895 264.099 203.982 266.116 189.07 268.133C190.448 273.125 191.824 278.118 193.202 283.111C220.316 276.572 249.104 271.854 273.936 258.577C280.654 254.985 277.676 243.778 270.014 244.104C238.173 245.459 206.49 249.685 175.408 256.724C176.784 261.717 178.162 266.709 179.54 271.702C194.953 266.01 210.367 260.319 225.78 254.626C239.646 249.506 255.003 245.585 266.735 236.27C271.996 232.091 267.625 222.626 261.241 223.011C236.361 224.512 211.676 228.361 187.544 234.599C177.866 237.102 181.968 252.088 191.673 249.579Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("path", {
      d: "M504.784 241.744C481.989 239.462 459.139 239.337 436.327 241.464C437.431 236.947 438.535 232.431 439.638 227.913C444.878 230.899 451.089 231.888 456.915 233.073C464.087 234.533 471.26 235.989 478.433 237.448C492.778 240.364 507.123 243.28 521.468 246.196C530.899 248.113 529.552 261.88 519.721 261.196C490.129 259.137 460.51 259.748 431.027 263.025C431.568 258.198 432.107 253.369 432.647 248.542C457.097 257.13 484.449 257.041 510.036 259.18C514.13 259.522 517.082 263.592 516.666 267.552C516.193 272.05 512.348 274.104 508.289 274.18C495.367 274.423 482.444 274.666 469.522 274.908C463.401 275.023 457.278 275.138 451.157 275.253C444.767 275.373 438.015 274.882 431.689 275.745C432.892 275.212 434.096 274.678 435.299 274.145C435.043 274.354 434.787 274.564 434.532 274.774C435.432 272.436 436.334 270.099 437.234 267.761C437.281 267.961 437.326 268.161 437.371 268.361C435.808 266.403 434.244 264.447 432.68 262.49C432.868 262.588 433.056 262.686 433.243 262.785C430.732 263.172 428.22 263.56 425.71 263.948C425.834 263.846 425.959 263.742 426.084 263.64C433.613 257.457 443.121 269.189 435.608 275.36C435.482 275.462 435.358 275.564 435.233 275.668C433.316 277.241 430.014 278.039 427.699 276.831C424.631 275.23 423.092 273.49 422.306 270.066C421.721 267.519 423.064 264.649 425.009 263.054C430.011 258.95 438.649 260.521 444.743 260.406C451.884 260.272 459.026 260.138 466.166 260.004C480.789 259.729 495.413 259.455 510.036 259.18C509.453 264.18 508.871 269.18 508.289 274.18C481.274 271.921 452.923 271.658 427.101 262.587C420.116 260.134 421.314 248.927 428.722 248.104C459.508 244.683 490.567 244.044 521.467 246.195C520.885 251.195 520.303 256.195 519.72 261.195C504.07 258.014 488.421 254.833 472.771 251.651C458.693 248.789 443.361 247.301 430.71 240.093C425.036 236.86 427.831 227.12 434.021 226.543C458.141 224.294 482.424 224.33 506.529 226.744C516.196 227.712 514.479 242.715 504.784 241.744Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-eyes_14",
      cx: "350",
      cy: "250",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }