import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M387.44 178.57C371.89 178.57 362.32 165.81 350.96 165.81C339.6 165.81 325.04 178.57 309.73 178.57C294.42 178.57 286 167.46 286 152.06C286 135.41 302.75 106 348.61 106C394.47 106 414 133.74 414 151.46C413.96 167.83 403 178.57 387.44 178.57Z",
      fill: "#592D3D",
      stroke: "black",
      "stroke-width": "12",
      "stroke-miterlimit": "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M359.87 168.08C355.31 155.83 339.73 147.83 322.02 149.4C307.23 150.74 295.09 158.49 290.18 168.5C294.1 174.76 300.69 178.57 309.69 178.57C325.04 178.57 339.59 165.81 350.96 165.81C354.059 165.915 357.098 166.69 359.87 168.08Z",
      fill: "#F28195"
    }, null, -1),
    _createElementVNode("path", {
      d: "M303.789 141.49L304.659 141.14L305.389 140.89C305.859 140.73 306.329 140.61 306.799 140.49C307.736 140.249 308.688 140.072 309.649 139.96C311.586 139.701 313.547 139.678 315.489 139.89C319.559 140.279 323.431 141.835 326.639 144.37C329.669 146.765 331.943 149.984 333.189 153.64C334.329 157.017 334.399 160.662 333.389 164.08C330.889 161.5 328.929 159.14 326.959 157.2C326.06 156.275 325.098 155.412 324.079 154.62C323.155 153.923 322.147 153.345 321.079 152.9C319.069 152.066 316.904 151.674 314.729 151.75C313.581 151.782 312.435 151.882 311.299 152.05C310.729 152.15 310.139 152.24 309.579 152.36L308.739 152.55L308.349 152.63C308.259 152.63 308.059 152.71 308.129 152.63L303.789 141.49Z",
      fill: "#592D3D"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_9",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }