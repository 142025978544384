import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M335.181 129.17L216.085 109.936C210.596 109.049 205.123 108.064 199.618 107.276L199.381 107.238C194.218 106.404 188.359 111.262 187.523 117.94C187.042 121.191 187.596 124.458 189.068 127.05C190.539 129.642 192.813 131.356 195.409 131.831L314.506 151.065C319.985 151.95 325.468 152.936 330.973 153.724L331.21 153.762C336.373 154.596 342.232 149.739 343.068 143.06C343.546 139.809 342.991 136.544 341.52 133.953C340.049 131.362 337.776 129.647 335.181 129.17Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M578.645 83C578.645 83 539.794 69.4513 514.5 69.0187C485.71 68.5262 446.884 83.9003 441.324 84H441.084C435.854 84 430.844 89.7296 431.084 96.4557C431.128 99.7422 432.196 102.879 434.062 105.203C435.928 107.527 435.5 106.709 441.5 105.956C447.5 105.203 485.83 93.5187 514.5 93.5187C536.572 93.5187 557.24 101.747 578.645 105.456C584.964 105.456 588.851 101.245 588.645 95.4557C588.599 92.1702 587.53 89.0355 585.665 86.7119C583.799 84.3884 581.283 83.0576 578.645 83Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_5",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }