import Api2 from '@/core/services/Api2';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import { Hotjar } from '@/core/services/Hotjar';
import {
    calculateGameState,
    jclone,
    objectHash,
    roundUpToNearest10,
    shuffleArray,
    waitSec,
} from '@/core/helpers/utils';
import { delSession, fromSession, toSession } from '@/core/helpers/utils.cache';
import { omit } from 'lodash';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import CrashReportsApi from '@/core/services/AdminApi';
import TopicsFactory from '@/core/math-topics/TopicsFactory';
import CONSTANTS from '@/core/helpers/constants';
import { i18n } from '@/lang/translator';
import {
    CrashReportService,
    CrashReportTypes,
} from '@/core/services/CrashReportService';

export default {
    namespaced: true,
    state: {
        jwt: null,
        ioServer: null,
        initialized: false,
        noCache: false,
        mongoCode: null,
        math: null,
        info: null,
        players: [],
        leaderboard: [],
        playerStats: null,
        hostStats: {
            answers: [],
            accuracy: [],
        },
        currentRound: null,
        currentRoundQuestions: [],
        currentRoundQuestionsLength: 0,
        timerSync: null,
        skins: {
            avatars: {},
            customAvatars: {},
            backgrounds: {},
            frames: {},
        },
        quitConfirmed: false,
    },
    getters: {
        state: (state, getters, rootState) => {
            return rootState.v2.game.state;
        },
        code: (state, getters, rootState) => rootState.v2.game.code,
        jwt: (state) => state.jwt,
        socket: (state, getters, rootState) => {
            if (!getters.code) {
                return null;
            }

            if (!rootState.v2.io?.sockets[getters.code]) {
                return null;
            }

            return rootState.v2.io?.sockets[getters.code].socket || null;
        },
        ioOpen: (state, getters, rootState) => {
            if (!getters.code) {
                return false;
            }

            if (!rootState.v2.io?.sockets[getters.code]) {
                return false;
            }

            return rootState.v2.io?.sockets[getters.code].open || false;
        },
        ioConnected: (state, getters, rootState) => {
            if (!getters.code) {
                return false;
            }

            if (!rootState.v2.io?.sockets[getters.code]) {
                return false;
            }

            return rootState.v2.io?.sockets[getters.code].connected || false;
        },
        ioServer: (state) => state.ioServer,
        jwtPayload: (state) => {
            if (!state.jwt) {
                return null;
            }

            let jwt;

            try {
                jwt = jwtDecode(state.jwt);
            } catch (err) {
                console.error('decoding game jwt error!', err);
                jwt = null;
            }

            if (!jwt) {
                return null;
            }

            return jwt;
        },
        clientId: (state) => {
            if (!state.jwt) {
                return null;
            }

            let jwt;

            try {
                jwt = jwtDecode(state.jwt);
            } catch (err) {
                console.error('decoding game jwt error!', err);

                jwt = null;
            }
            if (!jwt) {
                return null;
            }

            return jwt.clientId;
        },
        isHost: (state, getters) => {
            return getters.jwtPayload ? getters.jwtPayload.isHost : false;
        },
        math: (state) => state.math,
        info: (state) => state.info,
        typeInfo: (state) => state.info,
        joinCode: (state, getters) => getters.code || '',
        creatorRole: (state) => state.info?.creatorRole,
        players: (state) =>
            state.players.filter((player) => !player.kicked) || [],
        hasPlayers: (state) => state.players.length > 0,
        onlinePlayers: (state) => state.players.filter((p) => p.isConnected),
        playerStats: (state) => state.playerStats,
        playerScore: (state) =>
            (state.playerStats?.score || 0) * CONSTANTS.SCORE_MULTIPLIER,
        leaderboard: (state) => state.leaderboard,
        currentRound: (state) => state.currentRound?.round,
        currentRoundEndTime: (state) => state.currentRound?.endTime,
        roundTimeLeft: (state, getters) => {
            const currentTimeMoment = moment();

            const timerEnd = moment(getters.currentRoundEndTime);

            const timeDiff = timerEnd.diff(currentTimeMoment);

            return timeDiff.utcOffset(0).format('mm:ss');
        },
        isCurrentRoundFinished: (state) => {
            const rounds = state.info?.gameRounds.length;

            if (!rounds) {
                return false;
            }

            return !!state.info.gameRounds[rounds - 1].hasEnded;
        },
        roundsCount: (state) => state.info?.round.rounds,
        roundDuration: (state) => state.info?.round.roundDuration,
        stats: (state) => state.rootGetters.getGameStatistics,
        roundQuestions: (state) => state.currentRound?.questions || [],
        leaderboardAccuracy: (state) => {
            if (
                !Array.isArray(state.leaderboard) ||
                !state.leaderboard.length
            ) {
                return 100;
            }

            let total = 0;

            let correct = 0;

            state.leaderboard.forEach((player) => {
                total +=
                    (player.correctAnswers || 0) + (player.wrongAnswers || 0);
                correct += player.correctAnswers || 0;
            });

            const accuracy = Math.round((correct * 100) / total);

            return accuracy || 0;
        },
    },
    mutations: {
        quitConfirmed: (state, next) => {
            state.quitConfirmed = next;
        },
        jwt: (state, next) => {
            state.jwt = next;
        },
        ioServer: (state, next) => {
            state.ioServer = next;
        },
        initialized: (state, next) => {
            if (state.initialized === next) return;

            state.initialized = next;
        },
        noCache: (state, next) => {
            if (state.noCache === next) return;

            state.noCache = next;
        },
        mongoCode: (state, next) => {
            if (state.mode === next) return;

            state.mongoCode = next;
        },
        classCode: (state, next) => {
            if (!state.info) return;

            state.info.classCode = next;
        },
        info: (state, info) => {
            console.log('store::game/tbl/setInfo', info);

            if (!info) {
                state.info = null;

                state.currentRound = null;

                return;
            }

            if (typeof info.code === 'number') {
                info.code = `${info.code}`;
            }

            state.info = jclone(info); // to break possible relations

            if (Array.isArray(info.gameRounds) && info.gameRounds.length) {
                const current = jclone(
                    info.gameRounds[info.gameRounds.length - 1],
                );
                console.log(`DEBUG: Setting current round`, current);
                state.currentRound = current;
            }
        },
        math: (state, next) => {
            state.math = next;
        },
        players: (state, next) => {
            state.players = next;
        },
        addPlayer: (state, player) => {
            // console.log('store::game/tbl/addPlayer', player, state);

            if (player.kicked || !player.isConnected) return;

            const ppos = state.players.findIndex(
                (p) => p.clientId === player.clientId,
            );

            if (ppos === -1) {
                state.players.push(player);
            } else {
                state.players[ppos] = player;
            }
        },
        updatePlayer: (state, player) => {
            const ppos = state.players.findIndex(
                (p) => p.clientId === player.clientId,
            );

            if (ppos === -1) {
                state.players.push(player);
            } else {
                state.players[ppos] = player;
            }
        },
        removePlayer: (state, player) => {
            state.players = state.players.filter((p) => p.clientId !== player);
        },
        timerSync: (state, next) => {
            state.timerSync = next;
        },
        currentRound: (state, next = null) => {
            // console.log('commit:currentRound', next);

            state.currentRound = next;
        },
        currentRoundQuestions: (state, next) => {
            // console.log('store::game/tbl.currentRoundQuestions', next);

            state.currentRoundQuestions = next || [];

            state.currentRoundQuestionsLength =
                state.currentRoundQuestions.length;
        },
        updateCurrentRoundQuestions: (state) => {
            if (!state.currentRound || !state.currentRound?.questions?.length) {
                state.currentRoundQuestions = [];

                state.currentRoundQuestionsLength = 0;

                return;
            }

            const from = state.currentRoundQuestions.length;

            const to = state.currentRound.questions.length;

            const diff = to - from;

            if (diff <= 0) {
                return;
            }

            const update = jclone(
                state.currentRound.questions.slice(diff * -1),
            );

            let i = 0;

            while (i * CONSTANTS.TBL_QUESTIONS_SHUFFLE_SLICE_SIZE < diff) {
                const slice = update.slice(
                    i * CONSTANTS.TBL_QUESTIONS_SHUFFLE_SLICE_SIZE,
                    (i + 1) * CONSTANTS.TBL_QUESTIONS_SHUFFLE_SLICE_SIZE,
                );

                const shuffled = shuffleArray(slice);

                state.currentRoundQuestions =
                    state.currentRoundQuestions.concat(shuffled);
                i++;
            }

            state.currentRoundQuestionsLength =
                state.currentRound.questions.length;
        },
        stats: (state, stats) => {
            // console.log('store::game/tbl/stats', stats);

            state.info.started = stats.started;

            state.info.finished = stats.finished;
        },
        leaderboard: (state, next = []) => {
            // console.log('store::game/tbl/leaderboard', next);

            state.leaderboard = next;
        },
        playerStats: (state, next = []) => {
            state.playerStats = next;
        },
        playerAnswer: (state, answer) => {
            if (!state.playerStats) {
                console.error('playerAnswewr - NO STATS');

                return;
            }

            const round = answer.round - 1;

            state.playerStats.results.push(answer);

            if (!state.playerStats.answers) {
                state.playerStats.answers = [];
            }

            if (!state.playerStats.answers[round]) {
                state.playerStats.answers[round] = [];

                state.playerStats.answers[round].push(answer);
            }

            if (answer.questionBody.correct) {
                state.playerStats.correctAnswers++;

                state.playerStats.score++;
            } else {
                state.playerStats.wrongAnswers++;

                state.playerStats.score--;

                if (state.playerStats.score < 0) {
                    state.playerStats.score = 0;
                }
            }
        },
        resetHostStats: (state, next = []) => {
            if (Array.isArray(next)) {
                state.hostStats.answers = next;
            } else {
                state.hostStats = next;
            }
        },
        hostUpdateAccuracy: (state) => {
            const round = state.currentRound.round;

            let total = 0;

            let correct = 0;

            state.hostStats.answers.forEach((answer) => {
                if (answer.round !== round) return;

                total++;

                if (answer.questionBody.correct) {
                    correct++;
                }
            });

            const accuracy = Math.floor((correct * 100) / total);

            state.hostStats.accuracy[round - 1] = Math.floor(accuracy);
        },
        hostStatsAddAnswer: (state, answer) => {
            console.log('store::game/tbl::hostStatsAddAnswer', answer);

            state.hostStats.answers.push(answer);
        },
        resetHostMistakeAnswers: (state) => {
            state.hostStats.answers = state.hostStats.answers.map((item) => ({
                ...item,
                answerIsShown: false,
            }));
        },
        prepareRound: (state, roundData) => {
            // console.log('store::game/tbl::prepareRound', roundData);

            if (!state.info.gameRounds) {
                state.info.gameRounds = [];
            }

            if (state.info.gameRounds[roundData.round - 1]) {
                console.error('store::game/tbl/prepareRound already prepared');

                return;
            }

            const next = state.info.gameRounds.length;

            state.info.gameRounds[next] = roundData;

            state.currentRound = roundData;

            state.currentRoundQuestionsLength = 0;

            state.currentRoundQuestions = [];
        },
        startRound: async (state, { roundData }) => {
            if (!state.info.gameRounds[roundData.round - 1]) {
                console.error(
                    'store::game/tbl/startRound no round',
                    roundData,
                    jclone(state.info),
                );

                return;
            }

            // console.log('store::game/tbl::startRound', roundData);

            const round = state.info.gameRounds[roundData.round - 1];

            if (!state.info.started && roundData.round === 1) {
                state.info.started = roundData.started;
            }

            state.info.gameRounds[roundData.round - 1] = {
                ...round,
                ...roundData,
            };

            state.currentRound = {
                ...round,
                ...roundData,
            };
        },
        addRoundQuestions: (state, questions = []) => {
            /*
            console.log(
                'store::game/tbl::addRoundQuestions',
                [...questions],
                state.state,
            );
            */

            if (!state.info || !state.info.gameRounds) return;

            const round = state.info.gameRounds.length;

            questions.forEach((q) => {
                state.info.gameRounds[round - 1].questions.push(q);
                state.currentRound.questions.push(q);
            });
        },
        playerSkinAvatar: (state, data) => {
            state.skins.avatars[data.clientId] = data.avatar;
        },
        playerSkinCustomAvatar: (state, data) => {
            state.skins.customAvatars[data.clientId] = data.customAvatar;
        },
        playerSkinBackground: (state, data) => {
            state.skins.backgrounds[data.clientId] = data.background;
        },
        playerSkinFrame: (state, data) => {
            state.skins.frames[data.clientId] = data.frame;
        },
        setSkins: (state, skins) => {
            state.skins = skins || {
                avatars: {},
                customAvatars: {},
                backgrounds: {},
                frames: {},
            };
        },
    },
    actions: {
        init: async (store) => {
            store.commit('initialized', true);

            // console.log('store::game/tbl initialized');
        },
        reset: async (store) => {
            console.debug('store::game/tbl::reset');

            await Promise.all([
                store.dispatch(
                    'v2/io/close',
                    { code: store.getters.code },
                    { root: true },
                ),
                store.commit('jwt', null),
                store.dispatch('setInfo', null),
                store.commit('math', null),
                store.commit('timerSync', null),
                store.commit('setSkins', null),
                store.commit('resetHostStats'),
                store.commit('mongoCode', null),
                store.commit('currentRoundQuestions', []),
                store.commit('currentRound', null),
                store.dispatch('clearCache'),
            ]);
        },
        saveCache: (store, force = false) => {
            if (store.state.noCache && !force) {
                // console.log('store::game/tbl.saveCache NOCACHE');

                return;
            }

            store.commit('noCache', true);

            toSession('game/tbl', omit(store.state, 'math'));

            setTimeout(() => {
                store.commit('noCache', false);
            }, CONSTANTS.CACHE_SAVE_DELAY_IN_MS);
        },
        loadCache: (store) => {
            // console.log('store::game/tbl loading cache', cache);

            const cache = fromSession('game/tbl', null);

            if (!cache) return;

            store.commit('noCache', true);

            store.commit('jwt', cache.jwt);

            store.commit('ioServer', cache.ioServer);

            store.commit('setSkins', cache.skins);

            store.commit('currentRoundQuestions', cache.currentRoundQuestions);

            store.dispatch('setInfo', cache.info);

            let math = null;

            if (cache.info && cache.info.gameType) {
                math = TopicsFactory.getTopicObject(cache.info.gameType);
            }

            store.commit('math', math);

            if (cache.info && Array.isArray(cache.info.players)) {
                cache.info.players.forEach((p) => {
                    store.commit('addPlayer', p);
                });
            } else {
                store.commit('players', []);
            }

            store.commit('leaderboard', cache.leaderboard);

            store.commit('playerStats', cache.playerStats);

            store.commit('resetHostStats', cache.hostStats);

            store.commit('timerSync', cache.timerSync);

            store.commit('currentRound', cache.currentRound);

            if (cache.jwt && cache.ioServer) {
                store.dispatch('openSocket');
            }

            store.commit('noCache', false);
        },
        clearCache: () => {
            delSession('game/tbl');
        },
        // create tbl game
        create: async (store, payload) => {
            const crs = new CrashReportService(null);
            crs.startSession(CrashReportTypes.WS_NOT_CONNECTING);
            store.commit('v2/game/state', 'none', {
                root: true,
            });

            const user = store.rootGetters.user;

            console.debug('store::game/tbl::create', {
                numberGenerator: payload.numberGenerator,
            });

            const gameType = {
                ...payload.gameType,
                numberGenerator: jclone(payload.numberGenerator),
                cvcChallenge: null,
                language: payload.language,
            };
            const requireLogin = false;
            const studentAccounts = false;
            const debugUser = user?.flags?.debugUser;

            const payloadMetaData = payload.metaData ? payload.metaData : {};

            const gameData = {
                requireLogin,
                gameType,
                round: {
                    rounds: payload.rounds,
                    roundDuration: payload.roundDuration,
                },
                topic: payload.topic || '',
                demo: payload.demo || false,
                classCode: payload.classCode || null,
                metaData: {
                    ...payloadMetaData,
                    presetName: payload.presetName,
                    gameOriginInUI: payload.gameOriginInUI,
                    gameOriginInUIDetail: payload.gameOriginInUIDetail,
                    studentAccounts,
                    avatars: true,
                    showWarmUp: true, // Can be deleted soon, kept for smooth release.
                    debugUser,
                },
            };

            if (window.location.hostname !== 'localhost') {
                void CrashReportsApi().post(
                    'crash-reports/increase-live-game-daily-counter/createEndpointCalls',
                );
            }

            console.debug('store::game/tbl::createLiveGame', gameData);

            const data = await Api2.post('game/create', {
                data: gameData,
            });

            if (!data) {
                console.error('store::game/tbl::create api error', data);

                return false;
            }

            const math = TopicsFactory.getTopicObject(data.game.gameType);

            store.commit('jwt', data.token);
            store.commit(
                'ioServer',
                data.wsServerUrl ||
                    import.meta.env[`VITE_${data.game.region}_TBL_API_URL`],
            );
            store.commit('v2/game/state', 'tbl/host/created', {
                root: true,
            });
            store.commit('v2/game/code', data.game.code, {
                root: true,
            });
            store.dispatch('setInfo', data.game);
            store.commit('math', math);
            store.commit('resetHostStats');
            store.dispatch('saveCache');
            try {
                await store.dispatch('openSocket');
            } catch (error) {
                console.error('store::game/tbl::create ws error', error);
                crs.sendCrashReport(CrashReportTypes.WS_NOT_CONNECTING);
            }
            crs.endSession(CrashReportTypes.WS_NOT_CONNECTING);

            new TrackingService().track(EVENTS.LIVE_GAME_CREATED, {
                gameCode: data.code,
                fromPlayAgain: payload.fromPlayAgain,
                numberOfSkillSelected: payload.gameType.skillsList?.length || 1,
                whereClicked: sessionStorage.getItem('wherePlayBtnClicked'),
            });

            sessionStorage.removeItem('wherePlayBtnClicked');

            if (store.rootGetters['v2/user/isStudent']) {
                Hotjar.tagRecording(['Student created Live Game']);
            }

            return true;
        },
        leave: async (store) => {
            console.debug('store::game/tbl::leave');

            store.dispatch(
                'v2/io/close',
                { code: store.getters.code },
                { root: true },
            );

            store.dispatch('reset');
        },
        openSocket: async (store) => {
            store.dispatch(
                'v2/io/io',
                {
                    code: store.state.ioServer,
                    url: store.state.ioServer,
                    jwt: store.state.jwt,
                },
                { root: true },
            );

            const socket = await store.dispatch(
                'v2/io/open',
                {
                    code: store.getters.code,
                    ioCode: store.state.ioServer,
                    nsp: 'live',
                },
                { root: true },
            );

            if (!socket) {
                console.error('store::game/tbl::openSocket error - no socket');

                return false;
            }

            let connectResolve = null;
            let connectReject = null;

            socket.on('connect', () => {
                store.dispatch('ioConnected', true);

                if (connectResolve) {
                    connectResolve();

                    connectResolve = null;
                }

                store.commit('setWebsocketConnectionStatus', true, {
                    root: true,
                });
            });

            socket.on('connect_error', (error) => {
                // the connection was denied by the server
                // in that case, `socket.connect()` must be manually called in order to reconnect
                console.log('store::game/tbl::openSocket error: ', error);
                if (connectReject) {
                    connectReject(error);

                    connectReject = null;
                }
            });

            socket.on('disconnect', () => {
                console.log('store::game/tbl::openSocket socket disconnected');

                store.dispatch('ioConnected', false);

                store.commit('setWebsocketConnectionStatus', false, {
                    root: true,
                });
            });

            socket.on('game-info', (info) => {
                // console.log('io::store -> game-info', JSON.stringify(info));

                // console.log('io::store -> game-info');

                store.dispatch('setInfo', info);
            });

            socket.on('game-not-found', () => {
                // console.log('io::store game-not-found');

                store.dispatch('v2/reset', null, { root: true });
            });

            socket.on('mongoCode', (mongoCode) => {
                // console.log('io::store -> mongoCode', mongoCode);

                store.dispatch('setMongoCode', mongoCode);

                // uncommented due to need of socket connection for emojis
                // setTimeout(() => {
                //     store.dispatch('leave');
                // }, 10000);
            });

            socket.on('startRound', (data) => {
                // console.log('io::store -> timerSync', data);

                store.commit('stats', data.stats);

                store.dispatch('startRound', data);
            });

            socket.on('leaderboard', (data) => {
                // console.log('io::store -> leaderboard', data);

                store.dispatch('setLeaderboard', data);
            });

            socket.on('timerSync', (data) => {
                // console.log('io::store -> timerSync', data);

                store.dispatch('timerSync', data);
            });

            socket.on('playerSelectedFrame', (data) => {
                store.commit('playerSkinFrame', data);
            });

            socket.on('playerSelectedAvatar', (data) => {
                store.commit('playerSkinAvatar', data);
            });

            socket.on('playerSelectedCustomAvatar', (data) => {
                store.commit('playerSkinCustomAvatar', data);
            });

            socket.on('playerSelectedBackground', (data) => {
                store.commit('playerSkinBackground', data);
            });

            socket.on('playerDisconnected', (payload) => {
                store.dispatch('playerUpdate', payload);
            });

            socket.on('playerLeft', (payload) => {
                store.dispatch('removePlayer', { clientId: payload });
            });

            socket.on('removePlayer', (clientId) => {
                store.dispatch('removePlayer', { clientId });
            });

            socket.on('playerJoinGame', (payload) => {
                store.dispatch('playerUpdate', payload);
            });

            if (store.getters.isHost) {
                // bind host io events
                socket.on('playerAnswer', (data) => {
                    store.dispatch('hostPlayerAnswer', data);
                });
            } else {
                // bind player io events
                socket.on('roundPrepared', (data) => {
                    console.log('io.player::roundPrepared', data);

                    store.commit('prepareRound', data);
                });

                socket.on('waitForNewQuestions', (message) => {
                    console.log('io.player::newQuestions', message);

                    store.dispatch('addRoundQuestions', message.data);
                });

                socket.on('hostLeftGame', () => {
                    store.dispatch('hostLeftGame');
                });
            }

            console.log('store::game/tbl::openSocket socket binded');

            return new Promise((resolve, reject) => {
                connectResolve = resolve;
                connectReject = reject;

                socket.open(socket);
            });
        },
        closeSocket: (store) => {
            console.debug('store:game/tbl::closeSocket');

            store.dispatch('v2/io/close', this.getters.code);
        },
        socketRequest: (store, payload) =>
            new Promise((resolve, reject) => {
                const rejectOnError =
                    payload && typeof payload.rejectOnError === 'boolean'
                        ? payload.rejectOnError
                        : false;

                if (!store.getters.socket) {
                    console.error(
                        'store::game/tbl::socketRequest error - no socket!',
                        payload,
                    );

                    if (rejectOnError) {
                        return reject('NO_SOCKET');
                    }

                    return resolve(undefined);
                }

                let args = [];

                if (typeof payload === 'string') {
                    args.push(payload);
                }

                if (typeof payload === 'object') {
                    if (payload.data) {
                        if (Array.isArray(payload.data)) {
                            args = [...payload.data];
                        } else {
                            args = [payload.data];
                        }
                    }

                    args.unshift(payload.event);
                }

                try {
                    store.getters.socket.emit(...args, (result) => {
                        resolve(result);
                    });
                } catch (err) {
                    console.error(
                        'store::game/tbl::socketRequest error',
                        err,
                        payload,
                    );

                    if (rejectOnError) {
                        return reject(err);
                    }

                    resolve(undefined);
                }
            }),
        join: async (store, data) => {
            // console.log('store:game:joinGame', data);

            store.commit('jwt', data.token || null);

            store.commit(
                'ioServer',
                data.wsServerUrl ||
                    import.meta.env[`VITE_${data.info.region}_TBL_API_URL`],
            );

            store.dispatch('setInfo', data.info);

            await store.dispatch('openSocket');

            // @todo: fix noCache timeout for game.js
            store.dispatch('v2/game/saveCache', true, { root: true });

            store.dispatch('saveCache', true);
        },
        ioConnected: async (store, isConnected) => {
            console.debug(
                'store:game:ioConnected',
                isConnected,
                store.getters.state,
            );

            if (store.getters.state === 'tbl/host/created') {
                // 1st round preparing required
                await waitSec(1);

                // console.log('preparing 1st round');
                await store.dispatch('hostPrepareRound', 1);
            }

            store.dispatch('saveCache');
        },
        updateClass: async (store, payload) => {
            if (store.rootState.v2.game.mode !== 'tbl') return;

            if (!store.getters.socket) return;

            const ok = await store.dispatch('socketRequest', {
                event: 'updateClassCode',
                data: payload,
            });

            if (ok && payload.classCode) {
                store.commit('classCode', payload.classCode);
            }

            store.dispatch('saveCache');

            return ok;
        },
        setInfo: (store, info) => {
            // console.log('store::game/tbl::setInfo', jclone(info));

            // here is good point to calculate state of incoming info
            // and compare it with existing
            const calculatedState = calculateGameState(
                jclone(info),
                store.getters.isHost,
            );

            const stateOk = calculatedState === store.getters.state;

            store.commit('v2/game/state', calculatedState, { root: true });

            if (!stateOk) {
                console.debug(
                    'store::game/tbl::setInfo loaded game state is not same as in store',
                    {
                        store: store.getters.state,
                        calculated: calculatedState,
                        info: jclone(info),
                    },
                );
            }

            if (!info) {
                store.commit('players', []);

                store.commit('timerSync', null);

                store.commit('leaderboard', []);

                store.commit('playerStats', null);

                store.dispatch('v2/game/tbl/saveCache', null, { root: true });

                return;
            }

            // because join game code is numbers based - it
            // recognized automatically as number type
            if (info.code === 'number') {
                info.code = `${info.code}`;
            }

            store.commit('v2/game/code', info.code, { root: true });

            store.commit('v2/game/mode', 'tbl', { root: true });

            const math = info.gameType
                ? TopicsFactory.getTopicObject(info.gameType)
                : null;

            store.commit('math', math);

            store.commit('info', info);

            store.commit('players', info.players || []);

            store.commit('updateCurrentRoundQuestions');

            store.dispatch('v2/game/tbl/saveCache', null, { root: true });
        },
        reloadInfo: async (store, code) => {
            // console.log('store::game/tbl.reloadInfo');

            const info = await Api2.get(`game/${code}/info`);

            if (!info) {
                return null;
            }

            // console.log('store::game/tbl.reloadInfo new data from API');

            store.dispatch('setInfo', info);

            return info;
        },
        syncronize: async (store) => {
            // console.log('store::game/tbl.syncronize', store.state.code);

            if (!store.rootGetters['v2/io/currentSocket']?.connected) {
                await store.dispatch('reloadInfo', store.state.code);
            } else {
                try {
                    const info = await store.dispatch('socketRequest', {
                        event: 'getGameInfo',
                    });

                    // console.log('store::game/tbl io game info', info);

                    store.dispatch('setInfo', info);

                    if (info) {
                        info.players.forEach((player) => {
                            store.commit('addPlayer', player);
                        });
                    }

                    if (!store.getters.jwtPayload?.isHost) {
                        // console.log('update stats');

                        store.dispatch('getPlayerStats');
                    }
                } catch (err) {
                    console.error('io syncronize error', err);

                    return null;
                }
            }

            store.dispatch('v2/game/saveCache', null, { root: true });
        },
        playerUpdate: (store, payload) => {
            // console.log('store::game/tbl player join/update store', payload);

            store.commit('updatePlayer', payload);

            store.dispatch('saveCache');
        },
        kickPlayer: async (store, payload) => {
            // console.log('store::game/tbl kick player', payload);

            await store.dispatch('socketRequest', {
                event: 'removePlayer',
                data: payload,
            });

            store.dispatch('saveCache');
        },
        removePlayer: (store, payload) => {
            console.debug('store::game/tbl removePlayer', payload);

            store.commit('removePlayer', payload.clientId);

            const me = store.getters.clientId;

            if (me === payload.clientId) {
                store.dispatch(
                    'v2/ui/alert',
                    'You were removed from the game',
                    { root: true },
                );

                store.dispatch('leave');
            }

            store.dispatch('saveCache');
        },
        playerLeft: (store, clientId) => {
            // console.log('store::game/tbl player left', clientId);

            if (store.rootGetters['v2/user/isTeacher']) {
                const player = store.state.players.find(
                    (p) => p.clientId === clientId,
                );

                if (!player) {
                    console.error(
                        'store::game/tbl left player not found in game!',
                        clientId,
                    );

                    return;
                }

                store.commit('addPlayer', { ...player, isConnected: false });
            } else {
                store.dispatch('removePlayer', { clientId });
            }

            store.dispatch('saveCache');
        },
        playerRoundEnd: async (store) => {
            // console.log('store::game/tbl/playerRoundEnd', store.getters.state);

            if (store.getters.state === 'tbl/player/roundend') return;

            store.commit('v2/game/state', 'tbl/player/roundend', {
                root: true,
            });
        },
        hostLeftGame: (store) => {
            // console.log('store::game/tbl/hostLeftGame', store.getters.state);

            if (
                store.getters.state !== 'tbl/player/gameend' &&
                store.getters.state !== 'tbl/player/hostLeft'
            ) {
                store.commit('v2/game/state', 'tbl/player/hostLeft', {
                    root: true,
                });

                store.dispatch('v2/io/close', store.getters.code, {
                    root: true,
                });
            }

            store.dispatch('v2/game/saveCache', null, { root: true });

            store.dispatch('v2/ui/alert', i18n.t('player.hostLeftTheGame'), {
                root: true,
            });
        },
        hostEndRound: async (store) => {
            // console.log('correct endRound from host');

            if (store.getters.socket) {
                store.getters.socket.emit(
                    'currentRoundFinished',
                    store.getters.code,
                );
            }

            store.commit('v2/game/state', 'tbl/host/roundend', {
                root: true,
            });
        },
        hostPrepareRound: async (store, roundNum) => {
            // game info not exists yet
            if (!store.state.info) {
                console.error('prepare round not possible - no game info');

                return;
            }

            // round already prepared
            if (store.state.info.gameRounds?.length >= roundNum) {
                console.error('prepare round not possible - already prepared');

                return;
            }

            // generating questions
            if (!store.state.math) {
                const math = TopicsFactory.getTopicObject(
                    store.state.info.gameType,
                );

                if (!math) {
                    console.error(
                        'store::game/tbl::hostPrepareRound no math!',
                        {
                            ...store.state.info.gameType,
                        },
                    );

                    return;
                }

                store.commit('math', math);
            }

            const amount = roundUpToNearest10(
                store.state.info.round.roundDuration /
                    CONSTANTS.LIVE_GAME_SECONDS_PER_TASK,
            );

            const questions = store.state.math.generateQuestions(amount);

            console.log('store::game/tbl::hostPrepareRound', questions);

            const result = await store.dispatch('socketRequest', {
                event: 'hostPrepareRound',
                data: questions,
            });

            if (!result) {
                console.error('hostPrepareRound failed!');

                return;
            }

            console.log('store::game/tbl.hostPrepareRound result', result);

            store.commit('prepareRound', result);

            store.commit('v2/game/state', 'tbl/host/prepared', {
                root: true,
            });
        },
        hostStartingRound: async (store) => {
            if (store.getters.socket) {
                store.getters.socket.emit('hostStartRound', {
                    gameCode: store.getters.code,
                });
            }

            store.commit('v2/game/state', 'tbl/host/startingRound', {
                root: true,
            });
        },
        hostEndGame: async (store) => {
            // console.log('store:game.hostEndGame');

            await store.dispatch('saveResultsToDB', store.state.code);

            store.dispatch('v2/game/saveCache', null, { root: true });
        },
        hostPlayerAnswer: async (store, data) => {
            // console.log('store:game/hostPlayerAnswer', data);

            store.commit('hostStatsAddAnswer', data);

            const amount = roundUpToNearest10(
                store.state.info.round.roundDuration /
                    CONSTANTS.LIVE_GAME_SECONDS_PER_TASK,
            );

            const quarter = Math.floor(amount / 4);

            const left =
                store.state.currentRound.questions.length - data.answerCount;

            if (left <= quarter) {
                /*
                console.log(
                    'store:game/hostPlayerAnswer',
                    'generate more questions!',
                    amount,
                );
                */

                const questions = store.state.math.generateQuestions(amount);

                /*
                store.dispatch(
                    'v2/io/emit',
                    {
                        event: 'deliverQuestionsOnStudents',
                        data: [{ questions }],
                    },
                    { root: true },
                );
                */

                if (store.getters.socket) {
                    store.getters.socket.emit('deliverQuestionsOnStudents', {
                        questions,
                    });
                }

                store.dispatch('addRoundQuestions', questions);
            }

            store.dispatch('saveCache');
        },
        playerAnswer: async (store, answer) => {
            if (store.getters.state !== 'tbl/player/round') return;

            const copy = jclone(answer.questionBody);

            const qBody = omit(copy, [
                'playerAnswer',
                'correct',
                'time',
                'timestamp',
            ]);

            answer.questionBody.questionHash = await objectHash(qBody);

            answer.playerName = store.rootGetters['v2/user/playerName'];

            // console.log('store:game/playerAnswer', answer);

            store.commit('playerAnswer', answer);

            /*
            store.dispatch(
                'v2/io/emit',
                {
                    event: 'playerAnswer',
                    data: [answer],
                },
                { root: true },
            );
            */

            if (store.getters.socket) {
                store.getters.socket.emit('playerAnswer', answer);
            }
        },
        startRound: async (store, data) => {
            // console.log('store:game/startRound', data);

            const num = data.roundData.round;

            if (num < 1 || !store.state.info.gameRounds[num - 1]) {
                await store.dispatch('syncronize');
            }

            store.commit('startRound', data);

            store.commit('updateCurrentRoundQuestions');

            const role = store.getters.isHost ? 'host' : 'player';

            store.commit('v2/game/state', `tbl/${role}/round`, {
                root: true,
            });
        },
        latestRound: (store) => {
            console.error('store:game:actions:latestRound');

            const len = store.state.info.gameRounds.length;

            return store.state.info.gameRounds[len - 1];
        },
        // leaderboard arrives at round/game end
        // so can be used as signal of round end
        setLeaderboard: async (store, data) => {
            // console.log('store::game/tbl::setLeaderboard', data);

            store.commit('leaderboard', data);

            if (store.getters.isHost) {
                store.commit('hostUpdateAccuracy');
            }

            await store.dispatch('setRoundEnd');

            store.dispatch('saveCache');
        },
        setRoundEnd: async (store) => {
            // console.log('store::game/tbl::setRoundEnd');

            const role = store.getters.isHost ? 'host' : 'player';

            if (store.rootState.v2.game.state !== `tbl/${role}/gameend`) {
                store.commit('v2/game/state', `tbl/${role}/roundend`, {
                    root: true,
                });
            }

            if (store.getters.roundsCount > store.getters.currentRound) {
                if (store.getters.isHost) {
                    await store.dispatch(
                        'hostPrepareRound',
                        store.getters.currentRound + 1,
                    );
                }
            } else {
                await store.dispatch('setGameEnd');
            }

            return false;
        },
        setGameEnd: async (store) => {
            // console.log('store::game/tbl::setGameEnd');

            const role = store.getters.isHost ? 'host' : 'player';

            if (store.getters.roundsCount <= store.getters.currentRound) {
                store.commit('v2/game/state', `tbl/${role}/gameend`, {
                    root: true,
                });

                if (store.getters.isHost) {
                    await store.dispatch('hostEndGame');
                }
            }

            store.dispatch('saveCache');
        },
        setMongoCode: (store, mongoCode) => {
            store.commit('mongoCode', mongoCode);

            const gameFinishedState = `tbl/${
                store.getters.isHost ? 'host' : 'player'
            }/gameend/${mongoCode}`;

            store.commit('v2/game/state', gameFinishedState, { root: true });

            store.dispatch('saveCache');

            setTimeout(() => {
                if (store.getters?.state === gameFinishedState) {
                    console.debug(
                        'Leave game by timeout: ',
                        store.getters?.state,
                        store.getters.code,
                    );
                    store.dispatch('leave');
                }
            }, CONSTANTS.TBL_TIME_TO_CLOSE_SOCKET);
        },
        saveResultsToDB: async (store) => {
            /*
            const ok = await store.dispatch('v2/io/request', 'saveResultToDB', {
                root: true,
            });
            */

            if (!store.getters.socket) return;

            await store.dispatch('socketRequest', 'saveResultToDB');

            // console.log('store::game/tbl.saveResultToDB', ok);
        },
        getGameInfo: (store) => {
            // console.log('store::game/tbl.getGameInfo');

            return store.dispatch('syncronize');
        },
        getLeaderboard: (store) => {
            // console.log('getLeaderboard');

            if (!store.getters.socket) return;

            store.getters.socket.emit('getLeaderboard');

            // store.dispatch('v2/io/emit', 'getLeaderboard', { root: true });
        },
        getPlayerStats: async (store, gameCode) => {
            // console.log('io request playerStats');

            /*
            const stats = await store.dispatch(
                'v2/io/request',
                {
                    event: 'playerStats',
                    data: gameCode,
                },
                { root: true },
            );
            */

            if (!store.getters.socket) return;

            const stats = await store.dispatch('socketRequest', {
                event: 'playerStats',
                data: gameCode,
            });

            store.dispatch('updatePlayerStats', stats);
        },
        updatePlayerStats: (store, stats) => {
            store.commit('playerStats', stats);

            store.dispatch('saveCache');
        },
        addRoundQuestions: (store, questions) => {
            // console.log('store::game/tbl/addRoundQuestions', questions);

            store.commit('addRoundQuestions', questions);

            store.commit('updateCurrentRoundQuestions');

            store.dispatch('saveCache');
        },
        timerSync: (store, time) => {
            store.commit('timerSync', time);
        },
    },
};
