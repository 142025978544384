import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M321.068 82.0443H200.428C194.868 82.0443 189.308 81.9446 183.748 82.0443H183.508C178.278 82.0443 173.268 87.7739 173.508 94.5C173.552 97.7865 174.62 100.923 176.486 103.247C178.352 105.571 180.87 106.901 183.508 106.956H304.148C309.698 106.956 315.268 107.055 320.828 106.956H321.068C326.298 106.956 331.308 101.226 331.068 94.5C331.022 91.2145 329.954 88.0797 328.088 85.7562C326.223 83.4327 323.706 82.1019 321.068 82.0443Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M578.644 82.0443H458.004C452.444 82.0443 446.884 81.9446 441.324 82.0443H441.084C435.854 82.0443 430.844 87.7739 431.084 94.5C431.128 97.7865 432.196 100.923 434.062 103.247C435.928 105.571 438.446 106.901 441.084 106.956H561.724C567.284 106.956 572.844 107.055 578.404 106.956H578.644C583.874 106.956 588.884 101.226 588.644 94.5C588.598 91.2145 587.53 88.0797 585.664 85.7562C583.799 83.4327 581.282 82.1019 578.644 82.0443Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_1",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }