export const LEAGUES_CONFIG = [
    {
        id: 'titan',
        name: 'Titan',
        from: 97,
        to: 100,
        primaryColor: '#8CCB3A',
        secondaryColor: '#3F5C19',
    },
    {
        id: 'elite',
        name: 'Elite',
        from: 90,
        to: 97,
        primaryColor: '#F809F0',
        secondaryColor: '#72026E',
    },
    {
        id: 'grand',
        name: 'Grand',
        from: 75,
        to: 90,
        primaryColor: '#3B96F2',
        secondaryColor: '#122C47',
    },
    {
        id: 'premier',
        name: 'Premier',
        from: 50,
        to: 75,
        primaryColor: '#2CD8BB',
        secondaryColor: '#195C54',
    },
    {
        id: 'power',
        name: 'Power',
        from: 30,
        to: 50,
        primaryColor: '#F2A241',
        secondaryColor: '#824B0D',
    },
    {
        id: 'rising',
        name: 'Rising',
        from: 10,
        to: 30,
        primaryColor: '#A2BAC4',
        secondaryColor: '#425356',
    },
    {
        id: 'starters',
        name: 'Starters',
        from: 0,
        to: 10,
        primaryColor: '#AA7031',
        secondaryColor: '#512C16',
    },
];
