import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M273 121.909C377.453 120.198 400.599 122.629 428 114C421.5 140.009 400.599 166.491 364.394 171.859C360.464 172.442 343.114 171.044 338.963 170.777C309.843 168.901 287.094 148.788 273 121.909Z",
      fill: "#ED4F43",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_10",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }