import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "1001",
  height: "1400",
  viewBox: "0 0 1001 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M733 772H254V816H733V772Z",
      fill: "#181305"
    }, null, -1),
    _createElementVNode("path", {
      d: "M497.509 514.914C297.741 514.914 233 656.111 233 803.401C233 804.316 233.12 805.213 233.12 806.128H393.714C393.714 805.647 393.594 805.222 393.594 804.76C393.594 746.892 440.118 699.981 497.509 699.981C554.882 699.981 601.406 746.892 601.406 804.76C601.406 805.222 601.286 805.647 601.286 806.128H761.88C761.88 805.213 762 804.316 762 803.401C762.009 656.111 705.377 514.914 497.509 514.914Z",
      fill: "#19DD19",
      stroke: "black",
      "stroke-width": "20"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-headgear_25",
      cx: "501",
      cy: "672.367",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }