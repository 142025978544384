import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "hairColor",
      d: "M577.471 269.558C486.875 259.666 414.796 383.366 430.046 476.828C450.39 561.455 557.136 549.777 567.304 476.828C571.784 444.683 557.136 427.167 557.136 398.007C557.136 362.942 577.471 339.621 613.056 339.621C679.153 339.621 656.267 476.828 656.267 476.828C656.267 476.828 700.025 404.604 689.311 357.137C676.045 298.367 630.096 275.304 577.471 269.558Z",
      fill: "#758BFF",
      stroke: "black",
      "stroke-width": "12",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M681.407 356.043C674.534 347.742 663.396 344.88 655.604 337.434C653.628 335.615 651.607 333.811 649.677 332.004C647.748 330.198 645.877 327.816 643.846 326.075L637.325 321.755C635.199 320.14 633.033 318.573 630.894 316.977C622.212 310.719 613.115 305.058 603.666 300.033C594.13 295.011 588.081 285.55 578 281.738C588.695 283.87 601.46 285.73 611.361 290.301C621.325 294.829 630.813 300.339 639.686 306.748C641.847 308.412 644.053 310.033 646.206 311.723C648.358 313.412 650.447 315.167 652.5 316.976C654.553 318.785 656.536 320.665 658.489 322.567C660.442 324.469 662.347 326.434 664.222 328.421C671.71 336.346 675.908 346.628 681.407 356.043Z",
      fill: "white",
      "fill-opacity": "0.4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-hair-front_26",
      cx: "501",
      cy: "672.367",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }