import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M286.63 250H175",
      stroke: "black",
      "stroke-width": "20",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M525.63 250H414",
      stroke: "black",
      "stroke-width": "20",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-eyes_16",
      cx: "350",
      cy: "250",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }