import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M231 281.375C252.539 281.375 270 263.914 270 242.375C270 220.836 252.539 203.375 231 203.375C209.461 203.375 192 220.836 192 242.375C192 263.914 209.461 281.375 231 281.375Z\" fill=\"#1B1A1A\"></path><path d=\"M211.535 167.851C187.28 172.969 172.636 186.298 160 207.331L173.368 200.678C167.887 220.276 166.324 235.812 168.679 247.285C173.81 274.703 201.527 341.479 272.617 304.706L265.892 329C320.371 244.513 263.321 157.429 211.535 167.851Z\" fill=\"#260C4C\"></path><path d=\"M231.948 211.465C214.7 204.207 199.441 213.52 198.097 232.299C196.173 259.165 223.181 288.755 246.564 280.632C272.763 269.491 260.549 223.498 231.948 211.465Z\" fill=\"#00FAFF\"></path><path d=\"M469.689 285.375C448.15 285.375 430.689 267.914 430.689 246.375C430.689 224.836 448.15 207.375 469.689 207.375C491.229 207.375 508.689 224.836 508.689 246.375C508.689 267.914 491.229 285.375 469.689 285.375Z\" fill=\"#1B1A1A\"></path><path d=\"M489.154 171.851C513.409 176.969 528.053 190.298 540.689 211.331L527.321 204.678C532.803 224.276 534.365 239.812 532.01 251.285C526.879 278.703 499.162 345.479 428.073 308.706L434.797 333C380.318 248.513 437.368 161.429 489.154 171.851Z\" fill=\"#260C4C\"></path><path d=\"M468.741 215.465C485.989 208.207 501.248 217.52 502.592 236.299C504.516 263.165 477.508 292.755 454.126 284.632C427.926 273.491 440.14 227.498 468.741 215.465Z\" fill=\"#00FAFF\"></path><circle id=\"anchor-eyes_29\" cx=\"350\" cy=\"250\" r=\"10\"></circle>", 7)
  ])))
}
export default { render: render }