import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M278.647 81.3265C256.853 54.6017 217.009 87.8085 197.084 109.615C192.674 117.519 180.198 133.37 197.084 116.575C221.746 100.344 252.474 88.0014 277.5 104.5C281.254 104.5 283.563 100.339 283.44 94.6201C283.371 86.3772 283.102 86.7891 278.647 81.3265Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M478.298 81.3265C500.092 54.6017 539.937 87.8085 559.861 109.615C564.271 117.519 576.747 133.37 559.861 116.575C535.199 100.344 504.471 88.0014 479.445 104.5C475.692 104.5 473.382 100.339 473.505 94.6201C473.575 86.3772 473.843 86.7891 478.298 81.3265Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_15",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }