import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M531.562 83.0944C531.562 83.0944 516.276 69.4474 506.324 69.0117C494.996 68.5157 479.72 84.0013 477.532 84.1017H477.438C475.38 84.1017 473.409 89.8729 473.503 96.6478C473.52 99.9581 473.941 103.117 474.675 105.458C475.409 107.8 475.241 106.975 477.601 106.217C479.962 105.458 495.043 93.6895 506.324 93.6895C515.008 93.6895 523.141 101.978 531.562 105.713C534.049 105.713 535.578 101.472 535.497 95.6405C535.479 92.3312 535.058 89.1737 534.324 86.8333C533.59 84.4929 532.6 83.1525 531.562 83.0944Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M285.562 83.0944C285.562 83.0944 270.276 69.4474 260.324 69.0117C248.996 68.5157 233.72 84.0013 231.532 84.1017H231.438C229.38 84.1017 227.409 89.8729 227.503 96.6478C227.52 99.9581 227.941 103.117 228.675 105.458C229.409 107.8 229.241 106.975 231.601 106.217C233.962 105.458 249.043 93.6895 260.324 93.6895C269.008 93.6895 277.141 101.978 285.562 105.713C288.049 105.713 289.578 101.472 289.497 95.6405C289.479 92.3312 289.058 89.1737 288.324 86.8333C287.59 84.4929 286.6 83.1525 285.562 83.0944Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_9",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }