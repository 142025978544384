import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M347.776 153.103L243.298 92.783C238.483 90.003 233.718 87.1367 228.853 84.443L228.645 84.323C224.116 81.708 216.912 84.165 213.757 90.11C212.152 92.978 211.508 96.2282 211.962 99.174C212.416 102.12 213.932 104.53 216.19 105.897L320.667 166.217C325.473 168.992 330.247 171.863 335.112 174.557L335.32 174.677C339.849 177.292 347.053 174.835 350.208 168.89C351.811 166.022 352.453 162.773 351.999 159.828C351.545 156.883 350.031 154.472 347.776 153.103Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M534.285 84.3233L429.808 144.643C424.992 147.423 420.128 150.117 415.362 152.983L415.154 153.103C410.625 155.718 409.151 163.185 412.722 168.89C414.403 171.715 416.896 173.897 419.674 174.977C422.452 176.057 425.298 175.949 427.61 174.677L532.087 114.357C536.903 111.577 541.767 108.884 546.533 106.017L546.741 105.897C551.27 103.282 552.744 95.8152 549.173 90.1103C547.49 87.2881 544.998 85.1075 542.22 84.028C539.443 82.9485 536.598 83.0543 534.285 84.3233Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_4",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }