import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M311.98 248.663L148 197C148 197 182.816 291.344 217.636 301.452C252.452 311.56 311.98 248.663 311.98 248.663Z\" fill=\"#FF0000\" stroke=\"black\" stroke-width=\"12\"></path><path d=\"M215.434 224C215.434 224 208.041 252.982 226.825 259.125C245.609 265.268 257 237.053 257 237.053L215.434 224Z\" fill=\"black\"></path><path d=\"M388.001 248.663L551.98 197C551.98 197 517.164 291.344 482.345 301.452C447.528 311.56 388.001 248.663 388.001 248.663Z\" fill=\"#FF0000\" stroke=\"black\" stroke-width=\"12\"></path><path d=\"M489.566 222C489.566 222 496.959 251.034 478.175 257.188C459.391 263.342 448 235.076 448 235.076L489.566 222Z\" fill=\"black\"></path><circle id=\"anchor-eyes_24\" cx=\"350\" cy=\"250\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }