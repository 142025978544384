import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M558.418 623.593C545.548 647.593 498.418 661.043 494.588 655.013C490.288 648.283 532.438 599.693 508.488 548.873\" fill=\"black\" fill-opacity=\"0.25\"></path><path class=\"hairColor\" d=\"M500.15 438.523C369 438.523 247.79 544.963 226 673.713C226 673.713 427.33 688.943 500.66 606.963C496.01 629.613 482.94 646.833 485.15 650.293C488.23 655.133 526.07 644.373 536.4 625.073L532.74 619.593C582.65 637.713 680.23 660.173 746 591.973C702.52 501.713 604.61 438.523 500.15 438.523Z\" fill=\"#85C5E5\"></path><path d=\"M331.519 671.523C325.083 664.571 320.707 655.967 318.879 646.671C317.051 637.375 317.844 627.754 321.169 618.883C314.135 618.42 307.271 616.506 301.012 613.263C294.752 610.019 289.231 605.516 284.795 600.036C280.359 594.556 277.105 588.218 275.237 581.42C273.369 574.621 272.927 567.51 273.939 560.533C249.849 594.223 232.939 632.833 225.939 673.713C261.136 675.758 296.438 675.025 331.519 671.523Z\" fill=\"black\" fill-opacity=\"0.07\"></path><path d=\"M739.659 606.313C664.999 663.713 579.069 641.413 533.509 622.403C523.369 640.343 488.249 650.293 485.359 645.763C483.359 642.683 494.179 628.113 499.139 608.603C424.859 688.873 239.289 680.603 239.289 680.603\" stroke=\"#1B1A1A\" stroke-width=\"12\" stroke-miterlimit=\"10\"></path><circle id=\"anchor-hair-front_8\" cx=\"501\" cy=\"672.369\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }