import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M321.068 118.044H200.428C194.868 118.044 189.308 117.945 183.748 118.044H183.508C178.278 118.044 173.268 123.774 173.508 130.5C173.552 133.786 174.62 136.923 176.486 139.247C178.352 141.571 180.87 142.901 183.508 142.956H304.148C309.698 142.956 315.268 143.055 320.828 142.956H321.068C326.298 142.956 331.308 137.226 331.068 130.5C331.022 127.215 329.954 124.08 328.088 121.756C326.223 119.433 323.706 118.102 321.068 118.044Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M578.644 118.044H458.004C452.444 118.044 446.884 117.945 441.324 118.044H441.084C435.854 118.044 430.844 123.774 431.084 130.5C431.128 133.786 432.196 136.923 434.062 139.247C435.928 141.571 438.446 142.901 441.084 142.956H561.724C567.284 142.956 572.844 143.055 578.404 142.956H578.644C583.874 142.956 588.884 137.226 588.644 130.5C588.598 127.215 587.53 124.08 585.664 121.756C583.799 119.433 581.282 118.102 578.644 118.044Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_3",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }