import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "hairColor",
      d: "M252.049 1200C270.448 1214.03 281.3 1194.93 294.28 1186.93C323.53 1167.86 336.523 1212.02 366.881 1190.92C379.86 1181.91 387.477 1161.83 405.857 1159.83C424.288 1158.82 436.233 1178.89 449.213 1187.94C488.221 1216.04 491.457 1166.87 525.051 1163.82C561.862 1160.84 570.549 1225.05 607.379 1194.92C616.052 1186.92 618.193 1169.85 633.375 1172.86C647.48 1175.88 648.57 1198.98 663.715 1201.96C678.892 1205.01 679.986 1187.93 691.89 1182.92C700.545 1178.89 710.788 1191.88 725.644 1181.11C745.525 1166.65 740.642 1130.73 735.191 1109.63C729.79 1092.57 717.904 1077.53 714.613 1060.45C711.396 1041.42 720.05 1025.34 724.393 1007.27C734.156 966.092 728.719 930.996 691.89 901.875C637.682 859.737 591.107 895.846 533.701 907.905C484.948 917.958 431.886 903.898 380.969 904.907C323.525 906.896 263.972 965.129 250.997 1013.3C244.452 1038.37 239.037 1068.49 240.145 1093.58C241.197 1110.62 247.705 1125.69 245.545 1143.76L252.049 1200Z",
      fill: "#A74444",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("path", {
      d: "M252.049 1200C270.448 1214.03 281.3 1194.93 294.28 1186.93C323.53 1167.86 336.523 1212.02 366.881 1190.92C379.86 1181.91 387.477 1161.83 405.857 1159.83C424.288 1158.82 436.233 1178.89 449.213 1187.94C488.221 1216.04 491.457 1166.87 525.051 1163.82C561.862 1160.84 570.549 1225.05 607.379 1194.92C616.052 1186.92 618.193 1169.85 633.375 1172.86C647.48 1175.88 648.57 1198.98 663.715 1201.96C678.892 1205.01 679.986 1187.93 691.89 1182.92C700.545 1178.89 710.788 1191.88 725.644 1181.11C745.525 1166.65 740.642 1130.73 735.191 1109.63C729.79 1092.57 717.904 1077.53 714.613 1060.45C711.396 1041.42 720.05 1025.34 724.393 1007.27C734.156 966.092 728.719 930.996 691.89 901.875C637.682 859.737 591.107 895.846 533.701 907.905C484.948 917.958 431.886 903.898 380.969 904.907C323.525 906.896 263.972 965.129 250.997 1013.3C244.452 1038.37 239.037 1068.49 240.145 1093.58C241.197 1110.62 247.705 1125.69 245.545 1143.76L252.049 1200Z",
      fill: "black",
      "fill-opacity": "0.25",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-hair-back_19",
      cx: "501",
      cy: "672.369",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }