import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M326.001 82.0003C289.314 54.9496 220.465 73.3831 186.925 95.456C179.501 103.456 158.5 119.5 186.924 102.5C228.44 86.0717 281.942 88.7566 324.07 105.456C330.389 105.456 334.277 101.245 334.07 95.456C333.953 87.1126 333.5 87.5295 326.001 82.0003Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M430.577 82.0003C467.264 54.9496 536.113 73.3831 569.653 95.456C577.077 103.456 598.078 119.5 569.654 102.5C528.138 86.0717 474.636 88.7566 432.508 105.456C426.189 105.456 422.301 101.245 422.508 95.456C422.625 87.1126 423.078 87.5295 430.577 82.0003Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_14",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }