import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1412",
  viewBox: "0 0 1000 1412",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "hairColor",
      d: "M801.168 1209.3C790.618 1000.63 865.105 836.797 710.184 740.215C710.184 740.215 671.725 610.511 648.574 588.775C625.398 567.051 588 553.174 477.766 573.596C424.285 560.866 395.09 557.393 346.257 575.672C351.589 583.734 351.199 595.114 341.84 601.056C314.601 618.283 290.269 638.317 276.182 663.979C260.92 691.796 260.94 723.504 257.934 753.21C254.222 789.602 251.661 826.366 244.882 862.491C238.744 895.362 246.294 927.243 233.537 958.828C220.271 991.682 205.769 1024.48 196.564 1058.23C187.125 1092.98 182.182 1128.71 179.956 1164.18C176.48 1220.18 173.293 1288.42 211.242 1338.52C220.796 1341.08 212.271 1342.97 223.057 1343.27C223.057 1343.27 231.191 1294.49 234.668 1271.61C244.518 1314.44 227.239 1336.38 274.642 1348.66C322.03 1360.94 588 1343.27 588 1343.27C588 1343.27 735.061 1349.85 807.462 1400C807.462 1400 806.871 1322.72 801.168 1209.3Z",
      fill: "#0083FC",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("path", {
      d: "M801.168 1209.3C790.618 1000.63 865.105 836.797 710.184 740.215C710.184 740.215 671.725 610.511 648.574 588.775C625.398 567.051 588 553.174 477.766 573.596C424.285 560.866 395.09 557.393 346.257 575.672C351.589 583.734 351.199 595.114 341.84 601.056C314.601 618.283 290.269 638.317 276.182 663.979C260.92 691.796 260.94 723.504 257.934 753.21C254.222 789.602 251.661 826.366 244.882 862.491C238.744 895.362 246.294 927.243 233.537 958.828C220.271 991.682 205.769 1024.48 196.564 1058.23C187.125 1092.98 182.182 1128.71 179.956 1164.18C176.48 1220.18 173.293 1288.42 211.242 1338.52C220.796 1341.08 212.271 1342.97 223.057 1343.27C223.057 1343.27 231.191 1294.49 234.668 1271.61C244.518 1314.44 227.239 1336.38 274.642 1348.66C322.03 1360.94 588 1343.27 588 1343.27C588 1343.27 735.061 1349.85 807.462 1400C807.462 1400 806.871 1322.72 801.168 1209.3Z",
      fill: "black",
      "fill-opacity": "0.25",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-hair-back_20",
      cx: "501",
      cy: "672.369",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }