import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M300.051 100.667L183.522 131.891C178.152 133.33 172.755 134.673 167.411 136.208L167.179 136.27C162.127 137.624 158.771 144.455 160.743 150.89C161.636 154.053 163.479 156.806 165.883 158.568C168.287 160.33 171.064 160.963 173.626 160.333L290.156 129.109C295.516 127.673 300.922 126.327 306.267 124.792L306.499 124.73C311.551 123.376 314.907 116.545 312.935 110.11C312.04 106.949 310.196 104.197 307.793 102.436C305.39 100.674 302.614 100.04 300.051 100.667Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M595.177 136.27L478.648 105.046C473.277 103.607 467.932 102.072 462.536 100.729L462.304 100.667C457.252 99.3134 450.93 103.551 449.421 110.11C448.613 113.296 448.832 116.602 450.033 119.33C451.234 122.058 453.322 123.994 455.857 124.73L572.386 155.954C577.757 157.393 583.101 158.928 588.498 160.271L588.729 160.333C593.781 161.686 600.103 157.449 601.612 150.89C602.418 147.704 602.197 144.4 600.997 141.673C599.796 138.945 597.71 137.009 595.177 136.27Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_6",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }