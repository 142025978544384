import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      id: "anchor-eyes_19",
      cx: "350",
      cy: "250",
      r: "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M164.21 203.823C156.169 240.184 170.569 281.953 199.292 297.693C229.236 314.1 265.305 302.295 282.891 268.123C292.284 249.869 295.192 227.573 291.36 206.647C288.493 191.008 268.736 197.628 271.602 213.267C274.573 229.464 271.002 247.102 262.611 260.164C253.886 273.753 240.301 280.646 226.286 280.277C196.984 279.503 176.428 244.538 183.967 210.436C187.398 194.95 167.635 188.336 164.21 203.823Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("path", {
      d: "M410.21 203.823C402.169 240.184 416.569 281.953 445.292 297.693C475.236 314.1 511.305 302.295 528.891 268.123C538.284 249.869 541.192 227.573 537.36 206.647C534.493 191.008 514.736 197.628 517.602 213.267C520.573 229.464 517.002 247.102 508.611 260.164C499.886 273.753 486.301 280.646 472.286 280.277C442.984 279.503 422.428 244.538 429.967 210.436C433.398 194.95 413.635 188.336 410.21 203.823Z",
      fill: "black"
    }, null, -1)
  ])))
}
export default { render: render }