import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M186.507 131.74C186.507 131.74 225.358 145.289 250.652 145.722C279.442 146.214 318.268 130.84 323.828 130.74L324.068 130.74C329.298 130.74 334.308 125.011 334.068 118.285C334.024 114.998 332.957 111.862 331.091 109.538C329.225 107.213 329.652 108.032 323.652 108.785C317.652 109.538 279.322 121.222 250.652 121.222C228.58 121.222 207.912 112.993 186.507 109.285C180.189 109.285 176.301 113.496 176.507 119.285C176.554 122.57 177.622 125.705 179.488 128.029C181.353 130.352 183.87 131.683 186.507 131.74Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M432.084 131.74C432.084 131.74 470.934 145.289 496.228 145.722C525.018 146.214 563.844 130.84 569.404 130.74L569.644 130.74C574.874 130.74 579.884 125.011 579.644 118.285C579.6 114.998 578.533 111.862 576.667 109.538C574.801 107.213 575.229 108.032 569.229 108.785C563.229 109.538 524.899 121.222 496.228 121.222C474.157 121.222 453.488 112.993 432.084 109.285C425.765 109.285 421.877 113.496 422.084 119.285C422.13 122.57 423.198 125.705 425.064 128.029C426.929 130.352 429.446 131.683 432.084 131.74Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_13",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }