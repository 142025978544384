import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M312.52 113C328.07 113 337.64 125.76 349.01 125.76C360.38 125.76 374.92 113 390.28 113C405.64 113 414 124.12 414 139.52C414 156.17 397.25 185.52 351.4 185.52C305.55 185.52 286 157.78 286 140.07C286 123.72 296.97 113 312.52 113Z",
      fill: "white",
      stroke: "black",
      "stroke-width": "12",
      "stroke-miterlimit": "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M326 113V186",
      stroke: "black",
      "stroke-width": "12",
      "stroke-miterlimit": "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M376 111V189",
      stroke: "black",
      "stroke-width": "12",
      "stroke-miterlimit": "10"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_7",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }