import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      id: "anchor-brows_10",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1),
    _createElementVNode("ellipse", {
      cx: "258.5",
      cy: "95.5",
      rx: "22",
      ry: "10.5",
      fill: "#1B1A1A"
    }, null, -1),
    _createElementVNode("ellipse", {
      cx: "504.5",
      cy: "95.5",
      rx: "22",
      ry: "10.5",
      fill: "#1B1A1A"
    }, null, -1)
  ])))
}
export default { render: render }