import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M293 122.132C293 103.232 406.72 106.072 406.72 122.132C406.72 137.212 400.729 151.674 390.066 162.338C379.403 173.001 364.94 178.992 349.86 178.992C334.78 178.992 320.317 173.001 309.654 162.338C298.991 151.674 293 137.212 293 122.132Z",
      fill: "#592D3D",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("path", {
      d: "M344.861 143.272C327.911 142.272 313.131 150.022 308.211 161.492C316.392 170.134 327.078 175.992 338.764 178.24C350.451 180.488 362.548 179.012 373.351 174.022C375.598 172.985 377.773 171.799 379.861 170.472C378.941 156.362 363.921 144.452 344.861 143.272Z",
      fill: "#F28195"
    }, null, -1),
    _createElementVNode("path", {
      d: "M327.98 132.732C328.43 132.622 328.61 132.572 328.9 132.522L329.65 132.382C330.14 132.292 330.65 132.242 331.11 132.202C332.07 132.104 333.036 132.07 334 132.102C335.956 132.144 337.899 132.416 339.79 132.912C343.752 133.915 347.344 136.032 350.14 139.012C352.772 141.842 354.535 145.368 355.22 149.172C355.846 152.679 355.371 156.294 353.86 159.522C351.79 156.592 350.2 153.962 348.54 151.762C347.793 150.707 346.974 149.705 346.09 148.762C345.283 147.933 344.376 147.208 343.39 146.602C341.523 145.483 339.441 144.771 337.28 144.512C336.146 144.365 335.004 144.285 333.86 144.272C333.27 144.272 332.68 144.272 332.1 144.322L331.25 144.382H330.86C330.76 144.382 330.56 144.382 330.63 144.382L327.98 132.732Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_8",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }