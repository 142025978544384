import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M350 178C375.405 178 396 157.405 396 132C396 106.595 375.405 86 350 86C324.595 86 304 106.595 304 132C304 157.405 324.595 178 350 178Z",
      fill: "#592D3D",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("path", {
      d: "M342.761 149.445C330.764 146.572 319.211 150.298 314.24 157.861C319.006 165.075 325.916 170.613 333.995 173.694C342.075 176.775 350.917 177.243 359.277 175.033C361.017 174.574 362.726 174.005 364.393 173.328C365.544 163.087 356.292 152.677 342.761 149.445Z",
      fill: "#F28195"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_5",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }