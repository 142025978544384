import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M544 241C544 281.317 511.317 314 471 314C430.683 314 398 281.317 398 241C398 200.683 430.683 168 471 168C511.317 168 544 200.683 544 241ZM471 306C506.899 306 536 276.899 536 241C536 205.101 506.899 176 471 176C435.101 176 406 205.101 406 241C406 276.899 435.101 306 471 306Z\" fill=\"#352712\"></path><circle cx=\"472\" cy=\"241\" r=\"65\" fill=\"white\"></circle><circle cx=\"471\" cy=\"241\" r=\"35\" fill=\"#352712\"></circle><circle cx=\"481\" cy=\"253\" r=\"3\" fill=\"white\"></circle><ellipse class=\"bodyColor\" cx=\"471\" cy=\"295\" rx=\"65\" ry=\"31\" fill=\"#C19F6E\"></ellipse><circle cx=\"468\" cy=\"236\" r=\"10\" fill=\"white\"></circle><path d=\"M411 283C427.167 270.167 473.9 252.2 531.5 283\" stroke=\"#352712\" stroke-width=\"8\" stroke-linecap=\"round\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M302 241C302 281.317 269.317 314 229 314C188.683 314 156 281.317 156 241C156 200.683 188.683 168 229 168C269.317 168 302 200.683 302 241ZM229 306C264.899 306 294 276.899 294 241C294 205.101 264.899 176 229 176C193.101 176 164 205.101 164 241C164 276.899 193.101 306 229 306Z\" fill=\"#352712\"></path><circle cx=\"229\" cy=\"241\" r=\"65\" fill=\"white\"></circle><circle cx=\"229\" cy=\"241\" r=\"35\" fill=\"#352712\"></circle><circle cx=\"239\" cy=\"253\" r=\"3\" fill=\"white\"></circle><circle cx=\"226\" cy=\"236\" r=\"10\" fill=\"white\"></circle><ellipse class=\"bodyColor\" cx=\"229\" cy=\"295\" rx=\"65\" ry=\"31\" fill=\"#C19F6E\"></ellipse><path d=\"M169 283C185.167 270.167 231.9 252.2 289.5 283\" stroke=\"#352712\" stroke-width=\"8\" stroke-linecap=\"round\"></path><ellipse cx=\"229\" cy=\"309\" rx=\"45\" ry=\"24\" fill=\"#E65D92\"></ellipse><ellipse cx=\"471\" cy=\"309\" rx=\"45\" ry=\"24\" fill=\"#E65D92\"></ellipse><circle cx=\"350\" cy=\"250\" r=\"10\" id=\"anchor-eyes_35\"></circle>", 17)
  ])))
}
export default { render: render }