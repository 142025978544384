import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "500",
  viewBox: "0 0 700 500",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M521.961 209.701L537.041 198\" stroke=\"#352712\" stroke-width=\"5\" stroke-linecap=\"round\"></path><path d=\"M531.127 215.303L551.502 202.501\" stroke=\"#352712\" stroke-width=\"5\" stroke-linecap=\"round\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M444.718 212.636C423.214 217.788 406.898 229.648 397.043 240.707C394.664 243.377 395.208 247.434 398 249.67C400.95 252.034 405.263 251.456 407.853 248.703C416.165 239.867 429.766 230.243 447.661 225.955C467.709 221.152 493.613 222.935 524.129 240.864C527.428 242.802 531.718 241.808 533.673 238.518C535.531 235.393 534.582 231.341 531.458 229.481C497.957 209.538 468.422 206.956 444.718 212.636Z\" fill=\"#352712\"></path><path d=\"M177.061 212.701L162.137 201\" stroke=\"#352712\" stroke-width=\"5\" stroke-linecap=\"round\"></path><path d=\"M167.99 218.303L147.825 205.501\" stroke=\"#352712\" stroke-width=\"5\" stroke-linecap=\"round\"></path><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M261.284 212.636C282.788 217.788 299.104 229.648 308.959 240.707C311.338 243.377 310.794 247.434 308.002 249.67C305.052 252.034 300.739 251.456 298.149 248.703C289.837 239.867 276.236 230.243 258.341 225.955C238.293 221.152 212.389 222.935 181.873 240.864C178.574 242.802 174.284 241.808 172.329 238.518C170.471 235.393 171.42 231.341 174.544 229.481C208.045 209.538 237.58 206.956 261.284 212.636Z\" fill=\"#352712\"></path><ellipse cx=\"241\" cy=\"279\" rx=\"45\" ry=\"24\" fill=\"#E65D92\"></ellipse><ellipse cx=\"465\" cy=\"279\" rx=\"45\" ry=\"24\" fill=\"#E65D92\"></ellipse><circle cx=\"350\" cy=\"250\" r=\"10\" id=\"anchor-eyes_42\"></circle>", 9)
  ])))
}
export default { render: render }