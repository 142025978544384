import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "498",
  viewBox: "0 0 700 498",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      id: "anchor-eyes_6",
      cx: "350",
      cy: "249",
      r: "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M233.386 184.406L251.814 221.6C252.445 222.874 253.666 223.758 255.077 223.962L296.284 229.926C299.839 230.441 301.259 234.792 298.686 237.291L268.869 266.241C267.847 267.233 267.381 268.662 267.623 270.063L274.662 310.942C275.269 314.47 271.553 317.16 268.373 315.495L231.518 296.193C230.255 295.531 228.746 295.531 227.484 296.193L190.628 315.495C187.448 317.16 183.732 314.47 184.339 310.942L191.377 270.063C191.619 268.662 191.153 267.233 190.13 266.241L160.314 237.291C157.741 234.792 159.161 230.44 162.716 229.926L203.922 223.962C205.334 223.757 206.555 222.874 207.186 221.6L225.614 184.406C227.203 181.198 231.797 181.198 233.386 184.406Z",
      fill: "#FFC93E",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("path", {
      d: "M474.386 184.406L492.814 221.6C493.445 222.874 494.666 223.758 496.077 223.962L537.284 229.926C540.839 230.441 542.259 234.792 539.686 237.291L509.869 266.241C508.847 267.233 508.381 268.662 508.623 270.063L515.662 310.942C516.269 314.47 512.553 317.16 509.373 315.495L472.518 296.193C471.255 295.531 469.746 295.531 468.484 296.193L431.628 315.495C428.448 317.16 424.732 314.47 425.339 310.942L432.377 270.063C432.619 268.662 432.153 267.233 431.13 266.241L401.314 237.291C398.741 234.792 400.161 230.44 403.716 229.926L444.922 223.962C446.334 223.757 447.555 222.874 448.186 221.6L466.614 184.406C468.203 181.198 472.797 181.198 474.386 184.406Z",
      fill: "#FFC93E",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1)
  ])))
}
export default { render: render }