import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "1000",
  height: "1400",
  viewBox: "0 0 1000 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      class: "hairColor",
      d: "M766.402 489.332C740.577 464.869 711.074 445.999 681.344 431.454C568.372 376.182 433.504 376.182 320.532 431.454C290.802 445.999 261.299 464.869 235.474 489.332C194.792 527.867 180.591 585.33 198.826 637.317L262.193 801.377C264.482 770.19 263.077 743.712 270.416 721.339C271.116 719.206 271.872 717.094 272.672 715.02C302.942 636.554 403.534 607.714 476.197 653.963L500.938 669.714L525.679 653.963C598.336 607.714 698.934 636.554 729.204 715.02C730.004 717.1 730.76 719.206 731.46 721.339C738.805 743.712 737.999 770.19 740.288 801.377L803.05 637.317C821.29 585.33 807.089 527.867 766.402 489.332Z",
      fill: "#222221"
    }, null, -1),
    _createElementVNode("path", {
      d: "M592.791 632.129C621.549 606.914 649.363 567.617 663.153 523.766C676.021 482.865 673.598 449.742 665.375 424.117H665.37C640.09 413.09 613.848 404.74 587.112 399.067L587.096 399.061C560.36 393.388 533.129 390.392 505.871 390.066C512.161 391.506 629.272 419.718 599.236 520.588C568.377 624.232 500.938 669.708 500.938 669.708L525.679 653.958C546.525 640.692 569.672 633.637 592.791 632.129Z",
      fill: "white"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-hair-front_30",
      cx: "501",
      cy: "672.367",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }