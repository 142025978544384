import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M311.5 100C305.5 99.0003 296 106.5 279.501 85.5003C264.085 74.2683 247 79.0003 227.5 85.5003C215.576 92.5446 192.5 101.5 221.001 92.5446C240.5 88.5003 255.084 80.3085 275.5 93.5003C289.5 110 297.431 106.5 314 109C320.319 109 325.706 109.289 325.499 103.5C324.5 100 317.059 100.927 311.5 100Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("path", {
      d: "M449.506 100C455.506 99.0003 465.006 106.5 481.505 85.5003C496.921 74.2683 514.006 79.0003 533.506 85.5003C545.429 92.5446 568.506 101.5 540.005 92.5446C520.506 88.5003 505.922 80.3085 485.506 93.5003C471.506 110 463.575 106.5 447.006 109C440.687 109 435.3 109.289 435.506 103.5C436.506 100 443.947 100.927 449.506 100Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "12"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_16",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }