import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "300",
  viewBox: "0 0 700 300",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M307.862 159.851C298.932 157.458 291.768 152.456 287.593 145.674C285.053 141.551 286.35 136.166 290.494 133.648C294.629 131.136 300.043 132.434 302.584 136.568C304.458 139.615 308.335 141.998 313.194 143.155C318.509 144.401 324.375 144.213 330.589 144.014L372.721 142.645C378.688 142.452 384.327 142.269 389.379 140.81C393.994 139.469 397.651 137.01 399.408 134.053C401.88 129.902 407.272 128.541 411.449 131.002C415.632 133.471 417.019 138.841 414.55 142.992C410.492 149.815 403.318 155.003 394.343 157.607C387.161 159.688 379.819 159.924 373.343 160.133L331.209 161.497C324.45 161.714 316.792 161.956 309.207 160.178C308.751 160.078 308.305 159.969 307.862 159.851Z",
      fill: "black"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-mouth_13",
      cx: "350",
      cy: "150",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }