import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M187.508 131.956L308.148 131.956C313.708 131.956 319.268 132.055 324.828 131.956L325.068 131.956C330.298 131.956 335.308 126.226 335.068 119.5C335.024 116.214 333.957 113.077 332.091 110.753C330.225 108.429 327.706 107.099 325.068 107.044L204.428 107.044C198.878 107.044 193.308 106.945 187.748 107.044L187.508 107.044C182.278 107.044 177.268 112.774 177.508 119.5C177.554 122.785 178.623 125.92 180.488 128.244C182.353 130.567 184.87 131.898 187.508 131.956Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M572.068 62.0443H451.428C445.868 62.0443 440.308 61.9446 434.748 62.0443H434.508C429.278 62.0443 424.268 67.7739 424.508 74.5C424.552 77.7865 425.62 80.923 427.486 83.2472C429.352 85.5714 431.87 86.9012 434.508 86.9557H555.148C560.708 86.9557 566.268 87.0554 571.828 86.9557H572.068C577.298 86.9557 582.308 81.2261 582.068 74.5C582.022 71.2145 580.954 68.0797 579.088 65.7562C577.223 63.4327 574.706 62.1019 572.068 62.0443Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_11",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }