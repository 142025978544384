import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "1001",
  height: "1400",
  viewBox: "0 0 1001 1400",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"501\" cy=\"763\" r=\"10\" id=\"anchor-headgear_30\"></circle><path d=\"M310 801.001C285.667 746.334 207.4 640.801 89 656.001\" stroke=\"#DFA0C8\" stroke-width=\"12\" stroke-linecap=\"round\"></path><path d=\"M674 801C698.333 746.513 776.6 641.326 895 656.476\" stroke=\"#DFA0C8\" stroke-width=\"12\" stroke-linecap=\"round\"></path><circle cx=\"902\" cy=\"658\" r=\"16\" fill=\"#DFA0C8\"></circle><circle cx=\"81\" cy=\"658\" r=\"16\" fill=\"#DFA0C8\"></circle>", 5)
  ])))
}
export default { render: render }