import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "700",
  height: "498",
  viewBox: "0 0 700 498",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M256.373 277.373L199 220\" stroke=\"black\" stroke-width=\"20\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"></path><path d=\"M199 277.373L256.373 220\" stroke=\"black\" stroke-width=\"20\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"></path><path d=\"M501.373 277.373L444 220\" stroke=\"black\" stroke-width=\"20\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"></path><path d=\"M444 277.373L501.373 220\" stroke=\"black\" stroke-width=\"20\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"></path><circle id=\"anchor-eyes_17\" cx=\"350\" cy=\"249\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }