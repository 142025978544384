<template>
    <div
        class="salb-wrapper"
        :class="{
            'salb-wrapper__margin-top': !isGetPremium,
        }"
    >
        <span v-if="!isGetPremium" class="salb-included">
            Included with Premium:
        </span>
        <div
            class="salb-content"
            :class="{
                'salb-content__margin-bottom': isGetPremium,
            }"
        >
            <div class="salb-container">
                <span class="salb-heading">
                    {{ canSeeAcademy ? 'Personalized Path' : 'Solo Practice' }}
                </span>
                <div
                    v-for="option in soloPracticeOptions"
                    :key="option"
                    class="salb-option"
                >
                    <img
                        src="/images/teacher-premium/trial-modal/green-check.svg"
                        alt="checkmark"
                    />
                    <span>{{ option }}</span>
                </div>
            </div>
            <div class="salb-container">
                <span class="salb-heading">Live Mode</span>
                <div
                    v-for="option in liveModeOptions"
                    :key="option"
                    class="salb-option"
                >
                    <img
                        src="/images/teacher-premium/trial-modal/green-check.svg"
                        alt="checkmark"
                    />
                    <span>{{ option }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { FLAGS } from '@/core/flags.js';
export default {
    props: {
        isGetPremium: Boolean,
    },
    computed: {
        soloPracticeOptions() {
            return [
                'New individual game mode',
                'Adaptive and differentiated',
                'Data on progress, activity, and mistakes',
                'Excited students for years',
            ];
        },
        liveModeOptions() {
            return [
                'Mix multiple skills into one game',
                'Printable reports',
                'See most common mistakes over time',
                'Faster fluency progress',
            ];
        },
        canSeeAcademy() {
            return this.$store.getters.user?.flags?.[
                FLAGS.PAID_HOME_ACADEMY_EXPERIMENT
            ];
        },
    },
};
</script>
<style lang="scss" scoped>
.salb-wrapper {
    display: flex;
    flex-direction: column;

    &__margin-top {
        margin-top: 51px;

        @media screen and (max-width: $w-max-phone) {
            margin-top: 10px;
        }
    }
}
.salb-content {
    display: flex;
    justify-content: center;
    gap: 40px;

    @media screen and (max-width: $w-max-phone) {
        flex-direction: column;
        align-items: center;
        justify-content: normal;
        gap: 10px;
    }

    &__margin-bottom {
        margin-bottom: 70px;

        @media screen and (max-width: $w-max-phone) {
            margin-bottom: 20px;
        }
    }
}
.salb-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 310px;

    @media screen and (max-width: $w-max-desktop) {
        gap: 6px;
        width: 250px;
    }
}
.salb-heading {
    margin-bottom: 5px;
    color: #bd8cff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 28px;
    font-weight: 700;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 20px;
    }

    @media screen and (max-width: $w-max-phone) {
        font-size: 16px;
    }
}
.salb-option {
    display: flex;
    gap: 10px;

    span {
        opacity: 0.8;
        color: #fff;
        font-family: $inter-font-family;
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;

        @media screen and (max-width: $w-max-desktop) {
            font-size: 14px;
        }
    }
}
.salb-included {
    margin-bottom: 12px;
    opacity: 0.8;
    color: #fff;
    text-align: center;
    font-family: $inter-font-family;
    font-size: 16px;
    font-weight: 600;

    @media screen and (max-width: $w-max-desktop) {
        font-size: 14px;
    }
}
</style>
