import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M551.5 82.0443H458.004C452.444 82.0443 446.884 81.9446 441.324 82.0443H441.084C435.854 82.0443 430.844 87.7739 431.084 94.5C431.128 97.7865 432.196 100.923 434.062 103.247C435.928 105.571 438.446 106.901 441.084 106.956C486.938 106.956 551.5 106.956 551.5 106.956V82.0443Z\" fill=\"#1B1A1A\" stroke=\"black\" stroke-width=\"4\"></path><path d=\"M210.652 106.942L304.148 106.942C309.708 106.942 315.268 107.042 320.828 106.942L321.068 106.942C326.298 106.942 331.308 101.212 331.068 94.4863C331.024 91.1999 329.957 88.0633 328.091 85.7391C326.225 83.4149 323.706 82.0851 321.068 82.0306C275.215 82.0306 210.652 82.0306 210.652 82.0306L210.652 106.942Z\" fill=\"#1B1A1A\" stroke=\"black\" stroke-width=\"4\"></path><path d=\"M578.643 82.0443L578.645 82.0443C573.085 82.0443 567.525 81.9446 561.965 82.0443H561.725V106.956C561.725 106.956 574.598 107.024 578.403 106.956H578.643C583.873 106.956 588.883 101.226 588.643 94.5C588.597 91.2145 587.529 88.0797 585.663 85.7562C583.798 83.4327 581.281 82.1019 578.643 82.0443Z\" fill=\"#1B1A1A\" stroke=\"black\" stroke-width=\"4\"></path><path d=\"M183.509 106.942L183.508 106.942C189.068 106.942 194.628 107.041 200.188 106.942L200.428 106.942L200.428 82.0301C200.428 82.0301 187.555 81.9619 183.749 82.0301L183.509 82.0301C178.279 82.0301 173.269 87.7597 173.509 94.4858C173.555 97.7713 174.624 100.906 176.489 103.23C178.355 105.553 180.871 106.884 183.509 106.942Z\" fill=\"#1B1A1A\" stroke=\"black\" stroke-width=\"4\"></path><circle id=\"anchor-brows_12\" cx=\"381.5\" cy=\"97\" r=\"10\"></circle>", 5)
  ])))
}
export default { render: render }