import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M569.645 83C569.645 83 530.794 69.4513 505.5 69.0187C476.71 68.5262 437.884 83.9003 432.324 84H432.084C426.854 84 421.844 89.7296 422.084 96.4557C422.128 99.7422 423.196 102.879 425.062 105.203C426.928 107.527 426.5 106.709 432.5 105.956C438.5 105.203 476.83 93.5187 505.5 93.5187C527.572 93.5187 548.24 101.747 569.645 105.456C575.964 105.456 579.851 101.245 579.645 95.4557C579.599 92.1702 578.53 89.0355 576.665 86.7119C574.799 84.3884 572.283 83.0576 569.645 83Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("path", {
      d: "M324.069 83C324.069 83 285.218 69.4513 259.924 69.0187C231.134 68.5262 192.308 83.9003 186.748 84H186.508C181.278 84 176.268 89.7296 176.508 96.4557C176.552 99.7422 177.62 102.879 179.486 105.203C181.352 107.527 180.924 106.709 186.924 105.956C192.924 105.203 231.254 93.5187 259.924 93.5187C281.996 93.5187 302.664 101.747 324.069 105.456C330.388 105.456 334.275 101.245 334.069 95.4557C334.022 92.1702 332.954 89.0355 331.089 86.7119C329.223 84.3884 326.706 83.0576 324.069 83Z",
      fill: "#1B1A1A",
      stroke: "black",
      "stroke-width": "4"
    }, null, -1),
    _createElementVNode("circle", {
      id: "anchor-brows_7",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1)
  ])))
}
export default { render: render }