import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "756",
  height: "250",
  viewBox: "0 0 756 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      id: "anchor-brows_17",
      cx: "381.5",
      cy: "97",
      r: "10"
    }, null, -1),
    _createElementVNode("path", {
      d: "M192.5 106L216 81L240.723 106L262 81L285.5 106L307.5 81L327.5 106",
      stroke: "black",
      "stroke-width": "15",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M435.5 106L459 81L483.723 106L505 81L528.5 106L550.5 81L570.5 106",
      stroke: "black",
      "stroke-width": "15",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }